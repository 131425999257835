import { createSelector } from "reselect";

const searchState = (state) => {
  if (state && state.search) {
    return state.search;
  }
  return null;
};

const searchSelector = createSelector(
  [searchState],
  (state) => state //return search
);

/** settings **/
const searchSettingsSelector = createSelector(
  [searchSelector],
  (state) => state.settings
);

const searchReceiveSettingsSelector = createSelector(
  [searchSettingsSelector],
  (state) => state.receiveSettings
);
/** settings **/

/** global search results **/

const searchReceiveGlobalResultsSelector = createSelector(
  [searchSelector],
  (state) => state.searchResult
);

// const searchReceiveGlobalResultsCitySelector = createSelector(
//   [searchReceiveGlobalResultsSelector],
//   (state) => state.receiveSearchCitiesResult
// );

export const selectSearch = createSelector(
  [
    searchSelector,
    searchSettingsSelector,
    searchReceiveSettingsSelector,
    searchReceiveGlobalResultsSelector,
    // searchReceiveGlobalResultsCitySelector
  ],
  (search, settings, receiveSettings, searchResult) => {
    const getSettings = receiveSettings ? receiveSettings : {};
    const globalSearchResult = searchResult && searchResult.receiveSearchResult ? searchResult.receiveSearchResult : {};
    const localSearchEntityResult = searchResult && searchResult.entitiesResult && searchResult.entitiesResult.entities ? searchResult.entitiesResult.entities : [];
    const localSearchObjectResult = searchResult && searchResult.objectsResult && searchResult.objectsResult.objects ? searchResult.objectsResult.objects : [];
    const globalSearchResultEntities = searchResult && searchResult.receiveSearchResult && searchResult.receiveSearchResult.entities ? searchResult.receiveSearchResult.entities : [];
    const globalSearchResultObjects = searchResult && searchResult.receiveSearchResult && searchResult.receiveSearchResult.objects ? searchResult.receiveSearchResult.objects : [];
    const citiesResult = searchResult && searchResult.receiveSearchCitiesResult ? searchResult.receiveSearchCitiesResult : [];
    const companyResult = searchResult && searchResult.receiveSearchCompany ? searchResult.receiveSearchCompany : [];
    const processing = searchResult && searchResult.receiveSearchProcessing ? searchResult.receiveSearchProcessing.isFetching : false;

    return (
    {
      search,
      getSettings,
      settings,
      globalSearchResult,
      globalSearchResultEntities,
      globalSearchResultObjects,
      citiesResult,
      companyResult,
      localSearchEntityResult,
      localSearchObjectResult,
      processing,
    }
  );
})




