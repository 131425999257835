/** LOGIN CONSTANTS **/

export const RECEIVE_LOGIN_REQUEST = "RECEIVE_LOGIN_REQUEST";

/** USER CONSTANTS **/

export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_ERROR = "GET_CURRENT_USER_ERROR";

/**SETTINGS CONSTANTS**/

export const SEARCH_RECEIVE_SETTINGS_REQUEST =
  "SEARCH_RECEIVE_SETTINGS_REQUEST";
export const SEARCH_RECEIVE_SETTINGS_SUCCESS =
  "SEARCH_RECEIVE_SETTINGS_SUCCESS";
export const SEARCH_RECEIVE_SETTINGS_ERROR = "SEARCH_RECEIVE_SETTINGS_ERROR";

export const SEARCH_EDIT_SETTINGS_REQUEST = "SEARCH_EDIT_SETTINGS_REQUEST";
export const SEARCH_EDIT_SETTINGS_SUCCESS = "SEARCH_EDIT_SETTINGS_SUCCESS";
export const SEARCH_EDIT_SETTINGS_ERROR = "SEARCH_EDIT_SETTINGS_ERROR";

/** SEARCH CONSTANTS**/
export const SEARCH_GET_ENTITY_OBJECT_REQUEST =
  "SEARCH_GET_ENTITY_OBJECT_REQUEST";
export const SEARCH_GET_ENTITY_OBJECT_SUCCESS =
  "SEARCH_GET_ENTITY_OBJECT_SUCCESS";
export const SEARCH_GET_ENTITY_OBJECT_ERROR = "SEARCH_GET_ENTITY_OBJECT_ERROR";

export const SEARCH_GET_ENTITY_REQUEST = "SEARCH_GET_ENTITY_REQUEST";
export const SEARCH_GET_ENTITY_SUCCESS = "SEARCH_GET_ENTITY_SUCCESS";
export const SEARCH_GET_ENTITY_ERROR = "SEARCH_GET_ENTITY_ERROR";

export const SEARCH_GET_OBJECT_REQUEST = "SEARCH_GET_OBJECT_REQUEST";
export const SEARCH_GET_OBJECT_SUCCESS = "SEARCH_GET_OBJECT_SUCCESS";
export const SEARCH_GET_OBJECT_ERROR = "SEARCH_GET_OBJECT_ERROR";

export const SEARCH_RECEIVE_CITY_REQUEST = "SEARCH_RECEIVE_CITY_REQUEST";
export const SEARCH_RECEIVE_CITY_SUCCESS = "SEARCH_RECEIVE_CITY_SUCCESS";
export const SEARCH_RECEIVE_CITY_ERROR = "SEARCH_RECEIVE_CITY_ERROR";

export const SEARCH_RECEIVE_COMPANY_REQUEST = "SEARCH_RECEIVE_COMPANY_REQUEST";
export const SEARCH_RECEIVE_COMPANY_SUCCESS = "SEARCH_RECEIVE_COMPANY_SUCCESS";
export const SEARCH_RECEIVE_COMPANY_ERROR = "SEARCH_RECEIVE_COMPANY_ERROR";

/** LPS CONSTANTS **/
export const RECEIVE_LPS_CONFIGS_REQUEST = "RECEIVE_LPS_CONFIGS_REQUEST";
export const RECEIVE_LPS_CONFIGS_SUCCESS = "RECEIVE_LPS_CONFIGS_SUCCESS";
export const RECEIVE_LPS_CONFIGS_ERROR = "RECEIVE_LPS_CONFIGS_ERROR";

export const CHECK_LPS_USAGE_REQUEST = "CHECK_LPS_USAGE_REQUEST";
export const CHECK_LPS_USAGE_SUCCESS = "CHECK_LPS_USAGE_SUCCESS";
export const CHECK_LPS_USAGE_ERROR = "CHECK_LPS_USAGE_ERROR";

export const CREATE_EDIT_LPS_CONFIG_REQUEST = "CREATE_EDIT_LPS_CONFIG_REQUEST";
export const CREATE_EDIT_LPS_CONFIG_SUCCESS = "CREATE_EDIT_LPS_CONFIG_SUCCESS";
export const CREATE_EDIT_LPS_CONFIG_ERROR = "CREATE_EDIT_LPS_CONFIG_ERROR";

export const DELETE_LPS_CONFIG_REQUEST = "DELETE_LPS_CONFIG_REQUEST";
export const DELETE_LPS_CONFIG_SUCCESS = "DELETE_LPS_CONFIG_SUCCESS";
export const DELETE_LPS_CONFIG_ERROR = "DELETE_LPS_CONFIG_ERROR";

export const RUN_PROCESSING_LPS_REQUEST = "RUN_PROCESSING_LPS_REQUEST";
export const FINISH_PROCESSING_LPS_SUCCESS = "FINISH_PROCESSING_LPS_SUCCESS";
export const FINISH_PROCESSING_LPS_ERROR = "FINISH_PROCESSING_LPS_ERROR";
export const RECALCULATE_DEFAULT_PROFILE = "RECALCULATE_DEFAULT_PROFILE";
export const RESET_CURRENT_CALCULATION_REQUEST =
  "RESET_CURRENT_CALCULATION_REQUEST";
export const RESET_CURRENT_CALCULATION_SUCCESS =
  "RESET_CURRENT_CALCULATION_SUCCESS";

/**ADMIN CONSTANTS**/
export const ADMIN_GET_USERS_REQUEST = "ADMIN_GET_USERS_REQUEST";
export const ADMIN_GET_USERS_SUCCESS = "ADMIN_GET_USERS_SUCCESS";
export const ADMIN_GET_USERS_ERROR = "ADMIN_GET_USERS_ERROR";

export const ADMIN_GET_USER_LOGS_REQUEST = "ADMIN_GET_USER_LOGS_REQUEST";
export const ADMIN_GET_USER_LOGS_SUCCESS = "ADMIN_GET_USER_LOGS_SUCCESS";
export const ADMIN_GET_USER_LOGS_ERROR = "ADMIN_GET_USER_LOGS_ERROR";

export const ADMIN_CREATE_USER_REQUEST = "ADMIN_CREATE_USER_REQUEST";
// export const ADMIN_CREATE_USER_SUCCESS = "ADMIN_CREATE_USER_SUCCESS";
export const ADMIN_CREATE_USER_ERROR = "ADMIN_CREATE_USER_ERROR";

export const ADMIN_UPDATE_USER_REQUEST = "ADMIN_UPDATE_USER_REQUEST";
// export const ADMIN_UPDATE_USER_SUCCESS = "ADMIN_UPDATE_USER_SUCCESS";
export const ADMIN_UPDATE_USER_ERROR = "ADMIN_UPDATE_USER_ERROR";

export const ADMIN_DELETE_USER_REQUEST = "ADMIN_DELETE_USER_REQUEST";
export const ADMIN_DELETE_USER_SUCCESS = "ADMIN_DELETE_USER_SUCCESS";
export const ADMIN_DELETE_USER_ERROR = "ADMIN_DELETE_USER_ERROR";

export const ADMIN_GET_USER_DETAILS_REQUEST = "ADMIN_GET_USER_DETAILS_REQUEST";
export const ADMIN_GET_USER_DETAILS_SUCCESS = "ADMIN_GET_USER_DETAILS_SUCCESS";
export const ADMIN_GET_USER_DETAILS_ERROR = "ADMIN_GET_USER_DETAILS_ERROR";

/** ENTITY CONSTANTS **/
export const CREATE_NEW_ENTITY_REQUEST = "CREATE_NEW_ENTITY_REQUEST";
export const CREATE_NEW_ENTITY_SUCCESS = "CREATE_NEW_ENTITY_SUCCESS";
export const CREATE_NEW_ENTITY_ERROR = "CREATE_NEW_ENTITY_ERROR";

export const RECEIVE_ENTITY_DETAILS_REQUEST = "RECEIVE_ENTITY_DETAILS_REQUEST";
export const RECEIVE_ENTITY_DETAILS_SUCCESS = "RECEIVE_ENTITY_DETAILS_SUCCESS";
export const RECEIVE_ENTITY_DETAILS_ERROR = "RECEIVE_ENTITY_DETAILS_ERROR";

export const RECEIVE_ENTITY_NOTES_REQUEST = "RECEIVE_ENTITY_NOTES_REQUEST";
export const RECEIVE_ENTITY_NOTES_SUCCESS = "RECEIVE_ENTITY_NOTES_SUCCESS";
export const RECEIVE_ENTITY_NOTES_ERROR = "RECEIVE_ENTITY_NOTES_ERROR";

export const UPDATE_ENTITY_NOTE_REQUEST = "UPDATE_ENTITY_NOTE_REQUEST";
export const UPDATE_ENTITY_NOTE_SUCCESS = "UPDATE_ENTITY_NOTE_SUCCESS";
export const UPDATE_ENTITY_NOTE_ERROR = "UPDATE_ENTITY_NOTE_ERROR";

export const DELETE_ENTITY_REQUEST = "DELETE_ENTITY_REQUEST";
export const DELETE_ENTITY_SUCCESS = "DELETE_ENTITY_SUCCESS";
export const DELETE_ENTITY_ERROR = "DELETE_ENTITY_ERROR";
export const SET_ENTITY_DELETE="SET_ENTITY_DELETE";

export const DELETE_ENTITY_NOTE_REQUEST = "DELETE_ENTITY_NOTE_REQUEST";
export const DELETE_ENTITY_NOTE_SUCCESS = "DELETE_ENTITY_NOTE_SUCCESS";
export const DELETE_ENTITY_NOTE_ERROR = "DELETE_ENTITY_NOTE_ERROR";

export const UPDATE_ENTITY_REQUEST = "UPDATE_ENTITY_REQUEST";
export const UPDATE_ENTITY_SUCCESS = "UPDATE_ENTITY_SUCCESS";
export const UPDATE_ENTITY_ERROR = "UPDATE_ENTITY_ERROR";

export const RECEIVE_DEPENDENT_ENTITIES_REQUEST =
  "RECEIVE_DEPENDENT_ENTITIES_REQUEST";
export const RECEIVE_DEPENDENT_ENTITIES_SUCCESS =
  "RECEIVE_DEPENDENT_ENTITIES_SUCCESS";
export const RECEIVE_DEPENDENT_ENTITIES_ERROR =
  "RECEIVE_DEPENDENT_ENTITIES_ERROR";

export const RECEIVE_POSTCODE_REQUEST = "RECEIVE_POSTCODE_REQUEST";
export const RECEIVE_POSTCODE_SUCCESS = "RECEIVE_POSTCODE_SUCCESS";
export const RECEIVE_POSTCODE_ERROR = "RECEIVE_POSTCODE_ERROR";
export const RESET_POSTCODE = "RESET_POSTCODE";

/** OBJECT CONSTANTS **/
export const CREATE_NEW_OBJECT_REQUEST = "CREATE_NEW_OBJECT_REQUEST";
export const CREATE_NEW_OBJECT_SUCCESS = "CREATE_NEW_OBJECT_SUCCESS";
export const CREATE_NEW_OBJECT_ERROR = "CREATE_NEW_OBJECT_ERROR";

export const GET_OBJECTS_BY_OWNER_REQUEST = "GET_OBJECTS_BY_OWNER_REQUEST";
export const GET_OBJECTS_BY_OWNER_SUCCESS = "GET_OBJECTS_BY_OWNER_SUCCESS";
export const GET_OBJECTS_BY_OWNER_ERROR = "GET_OBJECTS_BY_OWNER_ERROR";

export const DELETE_OBJECT_REQUEST = "DELETE_OBJECT_REQUEST";
export const DELETE_OBJECT_SUCCESS = "DELETE_OBJECT_SUCCESS";
export const DELETE_OBJECT_ERROR = "DELETE_OBJECT_ERROR";
export const SET_OBJECT_DELETE = "SET_OBJECT_DELETE";

export const UPDATE_OBJECT_REQUEST = "UPDATE_OBJECT_REQUEST";
export const UPDATE_OBJECT_SUCCESS = "UPDATE_OBJECT_SUCCESS";
export const UPDATE_OBJECT_ERROR = "UPDATE_OBJECT_ERROR";


export const GET_OBJECT_BY_ID_REQUEST = "GET_OBJECT_BY_ID_REQUEST";
export const GET_OBJECT_BY_ID_SUCCESS = "GET_OBJECT_BY_ID_SUCCESS";
export const GET_OBJECT_BY_ID_ERROR = "GET_OBJECT_BY_ID_ERROR";
export const SHOW_OBJECT_ADDRESS_ON_MAP = "SHOW_OBJECT_ADDRESS_ON_MAP";
export const RESET_OBJECT_ADDRESS_ON_MAP="RESET_OBJECT_ADDRESS_ON_MAP";

/** OWNER CONSTANTS**/
export const RECEIVE_OBJECTS_OWNERS_REQUEST = "RECEIVE_OBJECTS_OWNERS_REQUEST";
export const RECEIVE_OBJECTS_OWNERS_SUCCESS = "RECEIVE_OBJECTS_OWNERS_SUCCESS";
export const RECEIVE_OBJECTS_OWNERS_ERROR = "RECEIVE_OBJECTS_OWNERS_ERROR";

/** LABEL CONSTANTS **/
export const RECEIVE_LABELS_LIST_REQUEST = "RECEIVE_LABELS_LIST_REQUEST";
export const RECEIVE_LABELS_LIST_SUCCESS = "RECEIVE_LABELS_LIST_SUCCESS";
export const RECEIVE_LABELS_LIST_ERROR = "RECEIVE_LABELS_LIST_ERROR";

export const CREATE_NEW_LABEL_REQUEST = "CREATE_NEW_LABEL_REQUEST";
export const CREATE_NEW_LABEL_SUCCESS = "CREATE_NEW_LABEL_SUCCESS";
export const CREATE_NEW_LABEL_ERROR = "CREATE_NEW_LABEL_ERROR";

export const DELETE_LABEL_REQUEST = "DELETE_LABEL_REQUEST";
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS";
export const DELETE_LABEL_ERROR = "DELETE_LABEL_ERROR";

export const UPDATE_LABEL_REQUEST = "UPDATE_LABEL_REQUEST";
export const UPDATE_LABEL_SUCCESS = "UPDATE_LABEL_SUCCESS";
export const UPDATE_LABEL_ERROR = "UPDATE_LABEL_ERROR";

/** TAGS CONSTANTS **/
export const RECEIVE_TAGS_LIST_REQUEST = "RECEIVE_TAGS_LIST_REQUEST";
export const RECEIVE_TAGS_LIST_SUCCESS = "RECEIVE_TAGS_LIST_SUCCESS";
export const RECEIVE_TAGS_LIST_ERROR = "RECEIVE_TAGS_LIST_ERROR";

export const CREATE_NEW_TAG_REQUEST = "CREATE_NEW_TAG_REQUEST";
export const CREATE_NEW_TAG_SUCCESS = "CREATE_NEW_TAG_SUCCESS";
export const CREATE_NEW_TAG_ERROR = "CREATE_NEW_TAG_ERROR";

export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_ERROR = "DELETE_TAG_ERROR";

export const UPDATE_TAG_REQUEST = "UPDATE_TAG_REQUEST";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_ERROR = "UPDATE_TAG_ERROR";

/** LEADS CONSTANTS**/
export const CHECK_BEST_LEAD_REQUEST = "CHECK_BEST_LEAD_REQUEST";
export const CHECK_BEST_LEAD_SUCCESS = "CHECK_BEST_LEAD_SUCCESS";
export const CHECK_BEST_LEAD_ERROR = "CHECK_BEST_LEAD_ERROR";

export const CHECK_FREE_LEAD_REQUEST = "CHECK_FREE_LEAD_REQUEST";
export const CHECK_FREE_LEAD_SUCCESS = "CHECK_FREE_LEAD_SUCCESS";
export const CHECK_FREE_LEAD_ERROR = "CHECK_FREE_LEAD_ERROR";

export const SHOW_ASSIGNED_LEADS = "SHOW_ASSIGNED_LEADS";
export const SHOW_SCHEDULED_LEADS = "SHOW_SCHEDULED_LEADS";
export const GO_TO_NEXT_LEAD = "GO_TO_NEXT_LEAD";
export const SET_LEAD_ID = "SET_LEAD_ID";
export const RECEIVE_LEADS_DATA_REQUEST = "RECEIVE_LEADS_DATA_REQUEST";
export const SET_CURRENT_USER_ID = "SET_CURRENT_USER_ID";
export const FIND_LEAD_FLAG = "FIND_LEAD_FLAG";
export const NEXT_LPS_LEAD = "NEXT_LPS_LEAD";
export const PREV_LPS_LEAD = "PREV_LPS_LEAD";
export const SET_LPS_LEAD_INDEX = "SET_LPS_LEAD_INDEX";
export const SKIP_UNASSIGNED_LEADS_IN_LPS_RATING =
  "SKIP_UNASSIGNED_LEADS_IN_LPS_RATING";
export const RECEIVE_LEADS_REQUEST = "RECEIVE_LEADS_REQUEST";
export const RECEIVE_LEADS_SUCCESS = "RECEIVE_LEADS_SUCCESS";
export const RECEIVE_LEADS_ERROR = "RECEIVE_LEADS_ERROR";

/** PDF CONSTANTS**/

export const SEND_PDF_REQUEST = "SEND_PDF_REQUEST";
export const SEND_PDF_SUCCESS = "SEND_PDF_SUCCESS";
export const SEND_PDF_ERROR = "SEND_PDF_ERROR";

export const SAVE_PDF_REQUEST = "SAVE_PDF_REQUEST";
export const SAVE_PDF_SUCCESS = "SAVE_PDF_SUCCESS";
export const SAVE_PDF_ERROR = "SAVE_PDF_ERROR";

export const CANCEL_PDF_REQUEST = "CANCEL_PDF_REQUEST";
export const CANCEL_PDF_SUCCESS = "CANCEL_PDF_SUCCESS";
export const CANCEL_PDF_ERROR = "CANCEL_PDF_ERROR";

export const CHECK_PDF_USAGE_REQUEST = "CHECK_PDF_USAGE_REQUEST";
export const CHECK_PDF_USAGE_SUCCESS = "CHECK_PDF_USAGE_SUCCESS";
export const CHECK_PDF_USAGE_ERROR = "CHECK_PDF_USAGE_ERROR";

export const CANCEL_PDF = "CANCEL_PDF";
export const SET_UNITS_FOR_SAVE = "SET_UNITS_FOR_SAVE";

/** Google Drive **/

export const GET_GOOGLE_DRIVE_REQUEST = "GET_GOOGLE_DRIVE_REQUEST";
export const GET_GOOGLE_DRIVE_SUCCESS = "GET_GOOGLE_DRIVE_SUCCESS";
export const GET_GOOGLE_DRIVE_ERROR = "GET_GOOGLE_DRIVE_ERROR";

export const DISCONNECT_GOOGLE_DRIVE_REQUEST = "DISCONNECT_GOOGLE_DRIVE_REQUEST";
export const DISCONNECT_GOOGLE_DRIVE_SUCCESS = "DISCONNECT_GOOGLE_DRIVE_SUCCESS";
export const DISCONNECT_GOOGLE_DRIVE_ERROR = "DISCONNECT_GOOGLE_DRIVE_ERROR";

export const CHECK_DRIVE_CONNECTION_REQUEST = "CHECK_DRIVE_CONNECTION_REQUEST";
export const CHECK_DRIVE_CONNECTION_SUCCESS = "CHECK_DRIVE_CONNECTION_SUCCESS";
export const CHECK_DRIVE_CONNECTION_ERROR = "CHECK_DRIVE_CONNECTION_ERROR";

export const RECEIVE_DOCS_GOOGLE_DRIVE_REQUEST = "RECEIVE_DOCS_GOOGLE_DRIVE_REQUEST";
export const RECEIVE_DOCS_GOOGLE_DRIVE_SUCCESS = "RECEIVE_DOCS_GOOGLE_DRIVE_SUCCESS";
export const RECEIVE_DOCS_GOOGLE_DRIVE_ERROR = "RECEIVE_DOCS_GOOGLE_DRIVE_ERROR";

export const ADD_NEW_FILES_GOOGLE_DRIVE_REQUEST = "ADD_NEW_FILES_GOOGLE_DRIVE_REQUEST";
export const ADD_NEW_FILES_GOOGLE_DRIVE_SUCCESS = "ADD_NEW_FILES_GOOGLE_DRIVE_SUCCESS";
export const ADD_NEW_FILES_GOOGLE_DRIVE_ERROR = "ADD_NEW_FILES_GOOGLE_DRIVE_ERROR";

export const SEND_NEW_FILES_GOOGLE_DRIVE_REQUEST = "SEND_NEW_FILES_GOOGLE_DRIVE_REQUEST";
export const SEND_NEW_FILES_GOOGLE_DRIVE_SUCCESS = "SEND_NEW_FILES_GOOGLE_DRIVE_SUCCESS";
export const SEND_NEW_FILES_GOOGLE_DRIVE_ERROR = "SEND_NEW_FILES_GOOGLE_DRIVE_ERROR";

