import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { selectLeadgen } from '../../selectors';
import {
  Typography,
  List,
  ListItem,
  Box,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  CircularProgress
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import styles from '../../Leadgen.styles';
import DefaultButton from '../../../../common/components/button/Button';
import {
  addNewFilesGoogleDriveRequest,
  receiveDocsGoogleDriveRequest,
  sendNewFilesGoogleDriveRequest
} from '../../actions/docsGoogleDrive';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const GoogleDrive = (props) => {
  const { driveFolderFilesList, newFilesDrive, driveFolderId, id, type, isFetching } = props;
  const dispatch = useDispatch();
  const [filesList, setFilesList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [onUpload, setOnUpload] = useState(false);
  const [flag, setFlag] = useState(false);
  // const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setFilesList(driveFolderFilesList);
  }, [driveFolderFilesList]);

  useEffect(() => {
    if (newFilesDrive.length > 0) {
      dispatch(addNewFilesGoogleDriveRequest(newFilesDrive, driveFolderId, id, type));
    }
    ;

    if (!newFilesDrive.length && flag) {
      dispatch(receiveDocsGoogleDriveRequest({ type, id }));
      setFlag(false);
    }
    ;

  }, [newFilesDrive]);

  const handleUpload = (event) => {
    event.preventDefault();

    const files: any = event.target.files;
    const selectFilesList = [];

    if (files.length > 0) {
      Array.from(files).forEach((file: never) => {
        selectFilesList.push(file);
      });
    }
    setSelectedFiles([...selectedFiles, ...selectFilesList]);
    setOnUpload(true);

  };

  const handleDeleteFiles = (name: any) => {
    setSelectedFiles(selectedFiles.filter((file: any) => file.name !== name));
  };

  const handleSave = () => {
    const formData: any = new FormData();

    if (selectedFiles.length > 0) {
      selectedFiles.forEach((file: any) => {
        formData.append('files', file, file.name);
      });
      // // @ts-ignore
      // for (let [name, value] of formData) {
      //   console.log('name', value.name);
      // }
      dispatch(sendNewFilesGoogleDriveRequest(formData));
    }
    setSelectedFiles([]);
    setFlag(true);
    setOnUpload(false);
  };

  const handleCancel = () => {
    setOnUpload(false);
    setSelectedFiles([]);

  };

  return (
    <>
      <Box sx={styles.containerGoogleDrive}>
        {isFetching ? (
          <Box sx={styles.tabContext}>
            <CircularProgress color="secondary" size={60} />
          </Box>
        ) : (
          <>
            {((filesList && !filesList.length) && (selectedFiles && !selectedFiles.length)) &&
              <Box sx={styles.tabContext}>
                <Typography variant="h6" component="div">
                  There is on files in the current folder yet
                </Typography>
              </Box>
            }
            {(!!filesList.length || !!selectedFiles.length) &&
              <Box sx={styles.containerList}>
                {!!selectedFiles.length &&
                  <Box>
                    <List sx={styles.selectList} dense>
                      {selectedFiles.map(({ name }, index) => (
                          <ListItem
                            key={index}
                            sx={styles.selectItem}
                            secondaryAction={
                              <IconButton onClick={() => handleDeleteFiles(name)} edge="end" aria-label="delete">
                                <ClearIcon sx={styles.iconList} />
                              </IconButton>
                            }
                          >
                            <ListItemText
                              primary={`${name}`}
                            />
                          </ListItem>
                        )
                      )
                      }
                    </List>
                  </Box>
                }
                <Box>
                  <List dense>
                    {filesList.map(({ id, name, createdTime }, index) => (
                      <ListItemButton key={id} href={`https://drive.google.com/file/d/${id}/view`} target="_blank">
                        <ListItemText primary={`${name}`} />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </Box>
            }
          </>
        )
        }
        <Box sx={styles.buttonUploadContainer}>
          {!onUpload ?
            (
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={styles.buttonUpload}
                // onClick={handleUpload}
              >
                Upload files
                <VisuallyHiddenInput
                  type="file"
                  accept=".pdf"
                  onChange={(event) => {
                    if (
                      event.target.files !== null &&
                      event.target?.files?.length > 0
                    ) {
                      handleUpload(event);
                    }
                  }
                  }
                  multiple
                />
              </Button>
            )
            :
            (
              <>
                <DefaultButton
                  variant="contained"
                  // style={styles.buttonSave}
                  styleType="save"
                  handleClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </DefaultButton>
                <DefaultButton
                  variant="contained"
                  // style={styles.buttonCancel}
                  styleType="cancel"
                  handleClick={() => handleCancel()}
                >
                  Cancel
                </DefaultButton>
              </>
            )
          }
        </Box>
      </Box>
    </>
  );
};

function mapStateToProps(state: any) {
  const {
    driveFolderData,
    driveFolderId,
    driveFolderFilesList,
    newFilesDrive,
    isFetching
  } = selectLeadgen(state);

  return { driveFolderData, driveFolderId, driveFolderFilesList, newFilesDrive, isFetching };
}

export default connect(mapStateToProps)(GoogleDrive);