import { Grid,
  Paper,
  Box,
  Typography,
  Collapse,
  Card,
  CardContent,
  Tab,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EntityInfoCard from './EntityInfoCard';
import styles from '../../Leadgen.styles';
import EntityNotes from './EntityNotes';
import Stakeholders from './Stakeholders';
import { connect, useDispatch } from 'react-redux';
import { receiveObjectsByOwnerRequest } from '../../actions/objects';
import { selectLeadgen } from '../../selectors';
import { ExpandMore } from './EntityInfoCard';
import EntityRelatedInfo from './EntityRelatedInfo';
import EntityObjects from './EntityObjects';
import eventEmitter, {
  types
} from '../../../../common/components/helpers/eventEmitter';
import MapGoogleDriveBlock from '../common/MapGoogleDriveBlock';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import UserAvatar from '../../../../common/components/avatar/UserAvatar';
// import { propsTypes } from '../../../../common/constants/field-constants';
// import { setupMain } from '@testing-library/user-event/setup/setup';
import DefaultButton from '../../../../common/components/button/Button';
import css from "../../../../common/components/button/Button.styles";

interface EntitiesBlockProps {
  entityObjectsList?: any;
  entityId?: string | null;
  owners?: any;
  objectsAmount?: any;
  addressForMap?: any;
}

const mapStyles = {
  height: '78vh',
  marginTop: '1rem'
};

const breakpointMapMoving: number = 1200;

function ColumnOne({ entityId, address, width, height, objectsAmount, mainInfo, objectList }) {
  const [expandedRelatedInfo, setExpandedRelatedInfo] = React.useState(false);
  const [expandedStakeholders, setExpandedStakeholders] = React.useState(false);
  const handleExpandedClickRelatedInfo = () => {
    setExpandedRelatedInfo(!expandedRelatedInfo);
  };
  const handleExpandedClickStakeholders = () => {
    setExpandedStakeholders(!expandedStakeholders);
  };

  return (
    <>
      <Grid
        item
        container
        // spacing={{ xs: 2, lg: 3 }}
        columnSpacing={{ xs: 1, lg: 1 }}
        rowSpacing={{ xs: 1, lg: 0 }}
        columns={16}
        sx={styles.grid}
      >
        <Grid item xs={16} sm={8} sx={styles.gridMargin}>
          <EntityInfoCard entityId={entityId} width={width} height={height} />
        </Grid>

        <Grid item xs={16} sm={8} sx={styles.gridMargin}>
          {/*<Paper*/}
          {/*  // sx={(width && width <= 1024)*/}
          {/*  // && (height && height <= 500)*/}
          {/*  // ? [styles.firstColumnHolder, styles.containerFullHeight] : styles.firstColumnHolder}*/}
          {/*  sx={styles.stackHolderContainer}*/}
          {/*>*/}
          <Card sx={styles.cardStakeholders}>
            <Box sx={styles.mainInfoHolder}>
              <Box sx={styles.nameHolder}>
                <Typography sx={[styles.contentTitle, styles.stackHolderTitle]} variant="h3">
                  Related info
                </Typography>

              </Box>
              <ExpandMore
                expand={expandedRelatedInfo}
                onClick={handleExpandedClickRelatedInfo}
                aria-expanded={expandedRelatedInfo}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>


            {/*<CardHeader*/}
            {/*  title="Stakeholders"*/}
            {/*  sx={{display: 'flex', flexDirection:"", justifyContent: 'center', alignItems: 'center', width: '100%', height: '6rem', }}*/}
            {/*  action={*/}
            {/*    <ExpandMore*/}
            {/*      expand={expanded}*/}
            {/*      onClick={handleExpandClick}*/}
            {/*      aria-expanded={expanded}*/}
            {/*      aria-label="show more"*/}
            {/*    >*/}
            {/*      <ExpandMoreIcon />*/}
            {/*    </ExpandMore>*/}
            {/*  }*/}

            {/*>*/}

            {/*</CardHeader>*/}
            {/*<Box sx={{display: 'flex', flexDirection:"", justifyContent: 'center', alignItems: 'center', width: '100%'}}>*/}
            {/*  <Typography*/}
            {/*    variant="h4"*/}
            {/*    component="div"*/}
            {/*    sx={styles.contentTitleMain}*/}
            {/*  >*/}
            {/*    Stakeholders*/}
            {/*  </Typography>*/}
            {/*  <ExpandMore*/}
            {/*    expand={expanded}*/}
            {/*    onClick={handleExpandClick}*/}
            {/*    aria-expanded={expanded}*/}
            {/*    aria-label="show more"*/}
            {/*  >*/}
            {/*    <ExpandMoreIcon />*/}
            {/*  </ExpandMore>*/}
            {/*</Box>*/}

            <Collapse in={expandedRelatedInfo} timeout="auto" unmountOnExit>
              <CardContent>
                <EntityRelatedInfo entityId={entityId} />
              </CardContent>
            </Collapse>

          </Card>

          {/*</Paper>*/}
        </Grid>
        <Grid item xs={16} sx={styles.gridMargin}>
          <Card sx={styles.cardStakeholders}>
            <Box sx={styles.mainInfoHolder}>
              <Box sx={styles.nameHolder}>
                <Typography sx={[styles.contentTitle, styles.stackHolderTitle]} variant="h3">
                  Stakeholders
                </Typography>
              </Box>
              <ExpandMore
                expand={expandedStakeholders}
                onClick={handleExpandedClickStakeholders}
                aria-expanded={expandedStakeholders}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
            <Collapse in={expandedStakeholders} timeout="auto" unmountOnExit>
              <CardContent>
                <Stakeholders entityId={entityId} />
              </CardContent>
            </Collapse>
          </Card>

        </Grid>

        <Grid item xs={16} sx={styles.gridMargin}>
          <Paper
            // sx={(width && width <= 1024) && (height && height <= 500)
            //   ? [styles.objectsContainer, styles.objectsContainerFullHeight]
            //   : styles.objectsContainer}
            sx={styles.objectsContainer}

          >
            <Box sx={styles.objectsHolderBox}>
              <Typography
                variant="body2"
                component="div"
                sx={[styles.objectContentTitle, styles.objectContentTitleEntity]}
              >
                Objects ({objectsAmount})
              </Typography>
              <Typography sx={styles.objectTextEntity} variant="body2" component="div">
                Total size: {mainInfo.totalSize}m²
              </Typography>
              <Typography sx={styles.objectTextEntity} variant="body2" component="div">
                Housing: {mainInfo.houseSize}m²
              </Typography>
              <Typography sx={styles.objectTextEntity} variant="body2" component="div">
                Other: {mainInfo.otherSize}m²
              </Typography>
              <Typography sx={styles.objectTextEntity} variant="body2" component="div">
                Value: {mainInfo.totalPrice} €
              </Typography>
            </Box>
            <Box sx={styles.infoRow}>
              <DefaultButton
                variant="contained"
                // sx={css.buttonEditObjects}
                styleType="editobjects"
                handleClick={() =>
                  eventEmitter.emit(types.openObjectSearchModal, entityId)
                }
              >
                Link objects
              </DefaultButton>
              <DefaultButton
                variant="contained"
                // sx={css.buttonDeleteObjects}
                styleType="deleteobjects"
                handleClick={() =>
                  eventEmitter.emit(types.openAllObjectUnlinkModal, {
                    unLinkType: 'all_objects',
                    entityId,
                    objectList
                  })
                }
              >
                Unlink all
              </DefaultButton>
            </Box>
            <EntityObjects entityId={entityId} />
          </Paper>
        </Grid>

        <Grid item xs={16} sx={{marginBottom:'1px'}}>
          <Paper
            // sx={(width && width <= 1024) && (height && height <= 500)
            //   ? [styles.secondColumnHolder, styles.containerFullHeight]
            //   : styles.secondColumnHolder
            sx={styles.secondColumnHolder}
          >
            <Typography
              variant="h4"
              component="div"
              sx={styles.contentTitleMain}
            >
              Notes
            </Typography>
            <EntityNotes entityId={entityId} />
          </Paper>
        </Grid>


        {/*<Grid item xs={16} sm={8}>*/}
        {/*  <EntityInfoCard entityId={entityId} width={width} height={height} />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={16} sm={8}>*/}
        {/*  <Paper*/}
        {/*    sx={(width && width <= 1024) && (height && height <= 500) ? [styles.firstColumnHolder, styles.containerFullHeight] : styles.firstColumnHolder}>*/}
        {/*    <Typography*/}
        {/*      variant="h4"*/}
        {/*      component="div"*/}
        {/*      sx={styles.contentTitleMain}*/}
        {/*    >*/}
        {/*      Stakeholders*/}
        {/*    </Typography>*/}
        {/*    <Stakeholders entityId={entityId} />*/}
        {/*  </Paper>*/}
        {/*</Grid>*/}

        {/*{(width >= breakpointMapMoving)*/}
        {/*  && <MapBlock*/}
        {/*    address={address}*/}
        {/*    width={width}*/}
        {/*    height={height}*/}
        {/*  />}*/}


        {/*<Grid item xs={16}>*/}
        {/*  <Paper sx={[styles.container, styles.containerMap]}>*/}
        {/*    <Typography variant="h4" component="div" sx={styles.contentTitleMain}>*/}
        {/*      Maps*/}
        {/*    </Typography>*/}
        {/*    <Maps mapStyles={mapStyles} addressForMapDisplaying={address} />*/}
        {/*  </Paper>*/}
        {/*</Grid>*/}
      </Grid>
    </>
  );
}

function ColumnTwo({ address, entityId, objectsAmount, mainInfo, objectList, width, height }) {
  return (
    <>
      {/*<Grid*/}
      {/*  container*/}
      {/*  spacing={{ xs: 2, lg: 3 }}*/}
      {/*  columns={16}*/}
      {/*>*/}
      {/*  <Grid item xs={16}>*/}

      <MapGoogleDriveBlock
        address={address}
        entityId={entityId}
        mapStyles={mapStyles}
        />

      {/*</Grid>*/}

      {/*<Grid item xs={16} sm={8}>*/}
      {/*  <Paper*/}
      {/*    sx={(width && width <= 1024) && (height && height <= 500)*/}
      {/*      ? [styles.secondColumnHolder, styles.containerFullHeight]*/}
      {/*      : styles.secondColumnHolder}>*/}
      {/*    <Typography*/}
      {/*      variant="h4"*/}
      {/*      component="div"*/}
      {/*      sx={styles.contentTitleMain}*/}
      {/*    >*/}
      {/*      Notes*/}
      {/*    </Typography>*/}
      {/*    <EntityNotes entityId={entityId} />*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={16} sm={8}>*/}
      {/*  <Paper*/}
      {/*    sx={(width && width <= 1024) && (height && height <= 500)*/}
      {/*      ? [styles.secondColumnHolder, styles.containerFullHeight]*/}
      {/*      : styles.secondColumnHolder}>*/}
      {/*    <Typography*/}
      {/*      variant="h4"*/}
      {/*      component="div"*/}
      {/*      sx={styles.contentTitleMain}*/}
      {/*    >*/}
      {/*      Related info*/}
      {/*    </Typography>*/}
      {/*    <EntityRelatedInfo entityId={entityId} />*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={16} sx={styles.grid}>*/}
      {/*  <Paper*/}
      {/*    sx={(width && width <= 1024) && (height && height <= 500)*/}
      {/*      ? [styles.objectsContainer, styles.objectsContainerFullHeight]*/}
      {/*      : styles.objectsContainer}>*/}
      {/*    <Box sx={styles.objectsHolderBox}>*/}
      {/*      <Typography*/}
      {/*        variant="body2"*/}
      {/*        component="div"*/}
      {/*        sx={[styles.objectContentTitle, styles.objectContentTitleEntity]}*/}
      {/*      >*/}
      {/*        Objects ({objectsAmount})*/}
      {/*      </Typography>*/}
      {/*      <Typography sx={{ mr: '1rem' }} variant="body2" component="div">*/}
      {/*        Total size: {mainInfo.totalSize}m²*/}
      {/*      </Typography>*/}
      {/*      <Typography sx={{ mr: '1rem' }} variant="body2" component="div">*/}
      {/*        Housing: {mainInfo.houseSize}m²*/}
      {/*      </Typography>*/}
      {/*      <Typography sx={{ mr: '1rem' }} variant="body2" component="div">*/}
      {/*        Other: {mainInfo.otherSize}m²*/}
      {/*      </Typography>*/}
      {/*      <Typography variant="body2" component="div">*/}
      {/*        Value: {mainInfo.totalPrice} €*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*    <Box sx={styles.infoRow}>*/}
      {/*      <Button*/}
      {/*        variant="contained"*/}
      {/*        sx={styles.buttonLinkObjects}*/}
      {/*        onClick={() =>*/}
      {/*          eventEmitter.emit(types.openObjectSearchModal, entityId)*/}
      {/*        }*/}
      {/*      >*/}
      {/*        Link objects*/}
      {/*      </Button>*/}
      {/*      <Button*/}
      {/*        variant="contained"*/}
      {/*        sx={styles.buttonUnlinkObjects}*/}
      {/*        onClick={() =>*/}
      {/*          eventEmitter.emit(types.openAllObjectUnlinkModal, {*/}
      {/*            unLinkType: 'all_objects',*/}
      {/*            entityId,*/}
      {/*            objectList*/}
      {/*          })*/}
      {/*        }*/}
      {/*      >*/}
      {/*        Unlink all*/}
      {/*      </Button>*/}
      {/*    </Box>*/}
      {/*    <EntityObjects entityId={entityId} />*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}
      {/*{(width <= breakpointMapMoving)*/}
      {/*  && <MapBlock*/}
      {/*    address={address}*/}
      {/*    width={width}*/}
      {/*    height={height}*/}
      {/*  />}*/}
      {/*</Grid>*/}
    </>
  );
}

const EntitiesBlock = (props: EntitiesBlockProps) => {
  const dispatch = useDispatch();
  const { entityId, entityObjectsList, objectsAmount, addressForMap } = props;
  const [entityObjects, setObjects] = useState([]);
  const [addressForMapDisplaying, setAddressForMapsDisplaying] = useState(null);
  const [mainInfo, setMainInfo] = useState({
    houseSize: 'n/a',
    otherSize: 'n/a',
    totalPrice: 'n/a'
  } as any);

  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = (event) => {
      setWindowInnerWidth(event.target.innerWidth);
      setWindowInnerHeight(event.target.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth, window.innerHeight]);

  const getObjectListHeadInfo = (entityObjectsList: any[]) => {
    let houseSize = 0;
    let otherSize = 0;
    let totalPrice = 0;

    entityObjectsList &&
    entityObjectsList.length > 0 &&
    entityObjectsList.forEach(({ house_size, other_size, price }) => {
      houseSize += house_size || 0;
      otherSize += other_size || 0;
      totalPrice += Number(price) || 0;
    });

    let totalSize = houseSize + otherSize;
    return { houseSize, otherSize, totalPrice, totalSize };
  };

  useEffect(() => {
    dispatch(receiveObjectsByOwnerRequest(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    setObjects(entityObjectsList);
    setAddressForMapsDisplaying(addressForMap);
    const totalInfo = getObjectListHeadInfo(entityObjectsList);
    setMainInfo(totalInfo);
  }, [dispatch, entityObjectsList, addressForMap]);
  return (
    <Box sx={styles.holder}>
      <Grid
        container
        columns={16}
        // spacing={{ xs: 2, lg: 3 }}
        columnSpacing={{ xs: 1, lg: 1 }}
        rowSpacing={{ xs: 1, lg: 0 }}
      >
        <Grid item xs={16} lg={8}>
          <ColumnOne
            entityId={entityId}
            address={addressForMapDisplaying}
            width={windowInnerWidth}
            height={windowInnerHeight}
            objectsAmount={objectsAmount}
            mainInfo={mainInfo}
            objectList={entityObjects}

          />
        </Grid>
        <Grid item xs={16} lg={8}>
          <ColumnTwo
            address={addressForMapDisplaying}
            entityId={entityId}
            objectsAmount={objectsAmount}
            mainInfo={mainInfo}
            objectList={entityObjects}
            width={windowInnerWidth}
            height={windowInnerHeight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

function mapStateToProps(state: any) {
  const { entityObjectsList, objectsAmount, addressForMap } =
    selectLeadgen(state);
  return { entityObjectsList, objectsAmount, addressForMap };
}

export default connect(mapStateToProps)(EntitiesBlock);
