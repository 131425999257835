import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { selectLeadgen } from "../../selectors";
import {
  Box,
  TableContainer,
  TableCell,
  TableRow,
  Collapse,
  Table,
  TableHead,
  TableBody,
  IconButton,
  Paper,
  Link,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarRateIcon from "@mui/icons-material/StarRate";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import EditIcon from "@mui/icons-material/Edit";
import PlaceIcon from "@mui/icons-material/Place";
import kadIcon from "../../../../common/img/kadaster.jpeg";
import kadMapIcon from "../../../../common/img/kadastralekaart.png";
import styles from "../../Leadgen.styles";
import eventEmitter, {
  types,
} from "../../../../common/components/helpers/eventEmitter";
import { showAddressOnMap, updateObjectRequest } from "../../actions/objects";
import AlertService from "../../../../common/services/notification.service";

function createData(
  isCityOpen: boolean,
  city: string,
  fullSizeByCity: any,
  houseSizeByCity: any,
  totalOtherSizeByCity: any,
  totalPriceByCity: any,
  objectList: any
) {
  return {
    isCityOpen,
    city,
    fullSizeByCity,
    houseSizeByCity,
    totalOtherSizeByCity,
    totalPriceByCity,
    objectList,
  };
}

const EntityObjects = (props: any) => {
  const dispatch = useDispatch();
  const actionTypes = ["object_is_favorite"];
  const [objectId, setObjectId] = useState(null);
  const [currentFavorite, setCurrentFavorite] = useState<boolean>(false);

  const [filteredByCityObjects, setObjects] = useState([]);
  const { entityObjects, entityId } = props;

  const sortAndPackObjectsByCity = (objects: any) => {
    const currentCities = [
      // @ts-ignore
      ...new Set(entityObjects.map((i: any) => i.city)),
    ].sort();

    let totalSize = 0;
    let totalHouseSize = 0;
    let totalOtherSize = 0;
    let totalPrice = 0;

    const kad_sorter = (a: any, b: any) => {
      if (a.kadaster_id > b.kadaster_id) return 1;
      if (b.kadaster_id > a.kadaster_id) return -1;
      return 0;
    };

    return currentCities.map((i) => {
      let objectList = objects.filter((obj: any) => obj.city === i);

      objects.forEach(({ house_size, other_size, price }) => {
        totalHouseSize += house_size || 0;
        totalOtherSize += other_size || 0;
        totalPrice += Number(price) || 0;
      });

      totalSize = totalHouseSize + totalOtherSize;

      let updatedObjectList = objectList.map((object: any) => {
        return {
          ...object,
          isOpen: false,
        };
      });

      const are_favorite = updatedObjectList
        .filter((obj: any) => obj.is_favorite)
        .sort(kad_sorter);
      const have_street = updatedObjectList
        .filter((obj: any) => obj.street && !obj.is_favorite)
        .sort(kad_sorter);
      const others = updatedObjectList
        .filter((obj: any) => !obj.street && !obj.is_favorite)
        .sort(kad_sorter);

      updatedObjectList = [...are_favorite, ...have_street, ...others];

      return {
        city: i || "NO CITY",
        isCityOpen: false,
        fullSizeByCity: totalSize,
        houseSizeByCity: totalHouseSize,
        totalOtherSizeByCity: totalOtherSize,
        totalPriceByCity: totalPrice,
        objectList: updatedObjectList,
      };
    });
  };

  useEffect(() => {
    if (objectId !== null) {
      dispatch(
        updateObjectRequest(
          actionTypes,
          objectId,
          { is_favorite: currentFavorite },
          entityId
        )
      );
    }
  }, [dispatch, objectId, currentFavorite, entityId]);

  useEffect(() => {
    const preparedObjects =
      entityObjects &&
      entityObjects.length &&
      sortAndPackObjectsByCity(entityObjects);
    setObjects(preparedObjects);
  }, [entityObjects]); //

  const rows =
    filteredByCityObjects.length > 0
      ? filteredByCityObjects.map((item: any) => {
          return createData(
            item.isCityOpen, // todo check and remove
            item.city,
            item.fullSizeByCity,
            item.houseSizeByCity,
            item.totalOtherSizeByCity,
            item.totalPriceByCity,
            item.objectList.map((object: any) => ({
              id: object.object_id,
              isOpen: object.isOpen,
              isFavorite: object.is_favorite,
              kadaster_url: object.kadaster_url,
              objectAddress: object.objectAddress,
              owners: object.owner_count,
              full_size: object.full_size,
              purpose: object.purpose,
              price: object.price || "n/a",
              city_code: object.plot.city_code,
              section: object.plot.section,
              plot_number: object.plot.plot_number,
              units: object.units.map((unit: any) => {
                return {
                  unit_address: `${unit.address.street_name} ${
                    unit.address.number
                  } ${unit.address.number_add ? unit.address.number_add : ""}`,
                  unit_size: unit.size,
                  unit_purpose: unit.purpose,
                  unit_price: unit.price || "n/a",
                };
              }),
            }))
          );
        })
      : [];

  const newObjectList = rows.map((row) =>
    row.objectList.map((object: any) => `${row.city} ${object.objectAddress}`)
  );
  const updatedNewObjectList = [].concat(...newObjectList);

  const addressFirstObject = updatedNewObjectList.find(
    // @ts-ignore
    (el) => !el.includes("n/a") && !el.includes("null")
  );

  useEffect(() => {
    if (!addressFirstObject) return;

    dispatch(showAddressOnMap(addressFirstObject));
  }, [dispatch, addressFirstObject]);

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [openCity, setOpen] = useState(row.isCityOpen);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [updatedRow, setUpdatedRow] = useState(row); // todo check and remove
    const [objectList, setObjectList] = useState(
      row.objectList.length > 0 ? row.objectList : []
    );
    const [idCurrentObject, setIdCurrentObject] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, id: any) => {
      setAnchorEl(event.currentTarget);
      setIdCurrentObject(id);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const setRowOpen = (object: any) => {
      const updatedObjectList = objectList.map((obj: any) => {
        if (obj.id === object.id) {
          obj.isOpen = !obj.isOpen;
        }
        return obj;
      });

      setObjectList(updatedObjectList);
    };

    const showOnMap = (object: any) => {
      if (object.objectAddress) {
        dispatch(showAddressOnMap(`${row.city} ${object.objectAddress}`));
      } else {
        AlertService.warning(
          "Current object doesn't have an correct address or something went wrong"
        );
      }
    };

    const handleChangeFavorite = (object) => {
      setObjectId(object.id);
      setCurrentFavorite(!object.isFavorite);
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!openCity)}
            >
              {openCity ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {updatedRow.city}
          </TableCell>
          <TableCell align="right">{updatedRow.fullSizeByCity}</TableCell>
          <TableCell align="right">{updatedRow.houseSizeByCity}</TableCell>
          <TableCell align="right">{updatedRow.totalOtherSizeByCity}</TableCell>
          <TableCell align="right">{updatedRow.totalPriceByCity}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={openCity} timeout="auto" unmountOnExit>
              <Table>
                <TableHead sx={styles.collapseTableHead}>
                  <TableRow>
                    <TableCell sx={styles.tableCell} />
                    <TableCell sx={styles.tableCell} />
                    <TableCell sx={styles.tableCell} />
                    <TableCell sx={styles.tableCell} />
                    <TableCell sx={styles.tableCell} />
                    <TableCell sx={styles.tableCell} component="th" scope="row">
                      Address
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      Owners
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      Size, m²
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="center">
                      Purpose
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      Value, €
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Unlink all in city">
                        <IconButton
                          onClick={() =>
                            eventEmitter.emit(types.openAllObjectUnlinkModal, {
                              unLinkType: "all_objects_by_city",
                              entityId,
                              objectList,
                              city: updatedRow.city,
                            })
                          }
                        >
                          <LinkOffIcon sx={styles.icon} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.objectList.length > 0 &&
                    row.objectList.map((object: any) => {
                      return (
                        <React.Fragment key={object.id}>
                          <TableRow>
                            <TableCell sx={styles.tableCell}>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setRowOpen(object)}
                              >
                                {object.isOpen ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              <Tooltip title="Set as favorite">
                                <Checkbox
                                  checked={object.isFavorite}
                                  onChange={() => handleChangeFavorite(object)}
                                  icon={<StarOutlineIcon />}
                                  checkedIcon={<StarRateIcon />}
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Tooltip title="See on the map">
                                <IconButton onClick={() => showOnMap(object)}>
                                  <PlaceIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              <Tooltip title="Go to kadaster">
                                <IconButton>
                                  <Link
                                    href={object.kadaster_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      alt=""
                                      style={{
                                        // @ts-ignore
                                        "&:hover": {
                                          outline: "4px solid rgba(0,0,0,0.2)",
                                        },
                                        height: 20,
                                        width: 20,
                                      }}
                                      src={kadIcon}
                                    />
                                  </Link>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={styles.tableCell}>
                              <Tooltip title="Go tp kad.map">
                                <IconButton>
                                  <Link
                                    href={`https://kadastralekaart.com/kaart/perceel/${object.city_code}/${object.section}/${object.plot_number}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      alt=""
                                      style={{
                                        // @ts-ignore
                                        "&:hover": {
                                          outline: "4px solid rgba(0,0,0,0.2)",
                                        },
                                        height: 20,
                                        width: 20,
                                      }}
                                      src={kadMapIcon}
                                    />
                                  </Link>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              sx={styles.tableCell}
                              component="th"
                              scope="row"
                            >
                              {object.objectAddress}
                            </TableCell>
                            <TableCell sx={styles.tableCell} align="center">
                              {object.owners}
                            </TableCell>
                            <TableCell sx={styles.tableCell} align="center">
                              {object.full_size}
                            </TableCell>
                            <TableCell sx={styles.tableCell} align="center">
                              {object.purpose}
                            </TableCell>
                            <TableCell sx={styles.tableCell} align="right">
                              {object.price}
                            </TableCell>
                            <TableCell sx={styles.tableCell} align="right">
                              <Tooltip title="Menu">
                                <IconButton
                                  aria-label="actionMenuButton"
                                  onClick={(e) => handleOpenMenu(e, object.id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                sx={styles.relatedInfoItem}
                              >
                                <MenuItem onClick={handleClose}>
                                  <Tooltip
                                    title="Edit object"
                                    placement="right-start"
                                  >
                                    <ListItemIcon
                                      onClick={() =>
                                        eventEmitter.emit(
                                          types.openEntityObjectEditModal,
                                          "objectEdit",
                                          idCurrentObject
                                        )
                                      }
                                    >
                                      <EditIcon sx={styles.icon} />
                                    </ListItemIcon>
                                  </Tooltip>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                  <Tooltip
                                    title="Unlink object"
                                    placement="right-start"
                                  >
                                    <ListItemIcon
                                      onClick={() =>
                                        eventEmitter.emit(
                                          types.openAllObjectUnlinkModal,
                                          {
                                            unLinkType: "current_object",
                                            entityId,
                                            objectList,
                                            city: updatedRow.city,
                                            object_id: object.id,
                                          }
                                        )
                                      }
                                    >
                                      <LinkOffIcon sx={styles.icon} />
                                    </ListItemIcon>
                                  </Tooltip>
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={10}
                            >
                              {object.isOpen && object.units.length > 0 && (
                                <Collapse
                                  in={object.isOpen}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box sx={{ margin: "10px 10px 20px" }}>
                                    <Table
                                      size="small"
                                      aria-label="units_details"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="center">
                                            Unit address
                                          </TableCell>
                                          <TableCell align="center">
                                            Unit size, m²
                                          </TableCell>
                                          <TableCell align="center">
                                            Purpose
                                          </TableCell>
                                          <TableCell align="center">
                                            Value, €
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {object.units.map(
                                          (unit: any, index: number) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                              >
                                                {unit.unit_address}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                              >
                                                {unit.unit_size}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                              >
                                                {unit.unit_purpose}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                              >
                                                {unit.unit_price}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  /** EntityObjects return **/
  return (
    <TableContainer component={Paper} sx={styles.objectTable}>
      <Table
        aria-label="collapsible table"
        sx={{ maxWidth: "100%", width: "100%" }}
        size="small"
      >
        <TableHead sx={styles.mainTableHead}>
          <TableRow>
            <TableCell />
            <TableCell>City</TableCell>
            <TableCell align="right">Total size, m²</TableCell>
            <TableCell align="right">Housing, m²</TableCell>
            <TableCell align="right">Other, m²</TableCell>
            <TableCell align="right">Value, €</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map((row, index) => <Row key={index} row={row} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function mapStateToProps(state: any) {
  const { owners, entityObjects } = selectLeadgen(state);
  return { owners, entityObjects };
}

export default connect(mapStateToProps)(EntityObjects);
