import { createSelector } from "reselect";

const leadgenState = (state: any) => {
  if (state && state.leadgen) {
    return state.leadgen;
  }
  return null;
};

const leadgenSelector = createSelector(
  [leadgenState],
  (state) => state //return leadgen
);

/** entity details **/

const entityDetailsSelector = createSelector(
  [leadgenSelector],
  (state) => state.currentEntity
);

/** notes **/

const entityNotesSelector = createSelector(
  [leadgenSelector],
  (state) => state.entityNotes
);

/** objects **/

const objectsDetailsSelector = createSelector(
  [leadgenSelector],
  (state) => state.objects
);

const objectsStakeholdersSelector = createSelector(
  [leadgenSelector],
  (state) => state.objectsOwners
);

/** drive **/

const driveFolderSelector = createSelector(
  [leadgenSelector],
  (state) => state.driveFolder
);

const importFilesGoogleDriveFolderSelector = createSelector(
  [driveFolderSelector],
  (state) => state.importFilesGoogleDriveFolder
);

export const selectLeadgen: any = createSelector(
  [
    leadgenSelector,
    entityDetailsSelector,
    entityNotesSelector,
    objectsDetailsSelector,
    objectsStakeholdersSelector,
    driveFolderSelector,
    importFilesGoogleDriveFolderSelector,
  ],
  (leadgen, currentEntity, entityNotes, objects, objectsOwners, driveFolder, importFilesGoogleDriveFolder) => {
    const entityMainDetails =
      currentEntity && currentEntity.entity ? currentEntity.entity : {};
    const entityObjectsList =
      objects && objects.receiveObjects ? objects.receiveObjects : [];
    const entityObjectsListIds =
      entityObjectsList && entityObjectsList.length > 0
        ? entityObjectsList.map((object: any) => object.object_id)
        : [];
    const addressForMap = objects && objects.address ? objects.address : '';
    const owners = objectsOwners ? objectsOwners : [];
    const dependentEntities = currentEntity.dependentEntities
      ? currentEntity.dependentEntities
      : {};
    const currentContacts =
      dependentEntities.entities &&
      dependentEntities.entities.contactsData &&
      dependentEntities.entities.contactsData.length > 0
        ? dependentEntities.entities.contactsData
        : [];
    const currentManagers =
      dependentEntities.entities &&
      dependentEntities.entities.managersData &&
      dependentEntities.entities.managersData.length > 0
        ? dependentEntities.entities.managersData
        : [];
    const entityObjects =
      objects && objects.receiveObjects && objects.receiveObjects.length > 0
        ? objects.receiveObjects
        : [];
    const objectsAmount =
      objects && objects.receiveObjects && objects.receiveObjects.length > 0
        ? objects.receiveObjects.length
        : "";
    const currentObject =
      objects && objects.currentObject ? objects.currentObject : {};
    const isDeleteEntity =
      currentEntity?.responseRemoveEntity?.isDeletingEntity;
    const isDeleteObject = objects?.responseRemoveObject?.isDeletingObject;

    const driveFolderData: any = driveFolder.receiveGoogleDriveFolderData.folderData ? driveFolder.receiveGoogleDriveFolderData.folderData : {};
    const driveFolderId: any = driveFolderData && driveFolderData.id ? driveFolderData.id : "";
    const driveFolderFilesList: any = driveFolderData && driveFolderData.files ? driveFolderData.files : [];
    const isFetching: boolean = driveFolder.receiveGoogleDriveFolderData.isFetching;

    const newFilesDrive: any = importFilesGoogleDriveFolder.files ? importFilesGoogleDriveFolder.files : [];

    return {
      leadgen,
      entityMainDetails,
      entityNotes,
      entityObjectsList,
      entityObjectsListIds,
      entityObjects,
      objectsAmount,
      addressForMap,
      owners,
      currentContacts,
      currentManagers,
      currentObject,
      isDeleteEntity,
      isDeleteObject,
      driveFolderData,
      driveFolderId,
      driveFolderFilesList,
      newFilesDrive,
      isFetching
    };
  }
);
