import styles from "../GlobalSearch.styles";
import {
  Autocomplete,
  Box,
  Button, debounce,
  Grid,
  IconButton,
  InputBase,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CloseIcon from '@mui/icons-material/Close';
import { connect, useDispatch } from 'react-redux';
import { findEntityAndObjectByFiltersRequest } from '../actions/search';
import { selectSearch } from '../selectors';
import StyledInputBase from '../../../common/components/styled-components/StyledInputBase';
import StyledSearchIconWrapper from '../../../common/components/styled-components/StyledSearchIconWrapper';
import StyledSearch from '../../../common/components/styled-components/StyledSearch';
import {delay} from "redux-saga/effects";

function createEntityData(
  id: number,
  name: string,
  age: string,
  objects_amount: string,
  objects_size: string,
  housingPercent: string,
  mixedPercent: string
) {
  return {
    id,
    name,
    age,
    objects_amount,
    objects_size,
    housingPercent,
    mixedPercent,
  };
}

function createObjectData(
  id: number,
  street: string,
  full_size: any,
  units: any,
  price: string,
  owner: any
) {
  return {
    id,
    address: street,
    size: full_size,
    units_amount: units.length,
    price,
    owner,
  };
}

const SearchBlock = ({
  filters,
  globalSearchResultEntities,
  globalSearchResultObjects,
  processing,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState(null);
  const [showList, setShowList] = React.useState(false);
  const [entitiesResultList, setEntitiesResultList] = React.useState([]);
  const [objectsResultList, setObjectsResultList] = React.useState([]);
  // const loading = open && options.length === 0;
  const [pageEntities, setPageEntities] = useState(0);
  const [rowsPerPageEntities, setRowsPerPageEntities] = useState(5);
  const [pageObjects, setPageObjects] = useState(0);
  const [rowsPerPageObjects, setRowsPerPageObjects] = useState(5);
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - entityRows.length) : 0;

  const handleChangePageEntities = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageEntities(newPage);
  };

  const handleChangeRowsPerPageEntities = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageEntities(parseInt(event.target.value, 10));
    setPageEntities(0);
  };

  const handleChangePageObjects = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageObjects(newPage);
  };

  const handleChangeRowsPerPageObjects = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageObjects(parseInt(event.target.value, 10));
    setPageObjects(0);
  };

  useEffect(() => {
    setEntitiesResultList(globalSearchResultEntities);
    setObjectsResultList(globalSearchResultObjects);
  }, [dispatch, globalSearchResultEntities, globalSearchResultObjects]);

  useEffect(() => {
    let active = true;
    let newOptions: any = [];
    // if (!loading) {
    //   return undefined;
    // }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (active) {
      if (value) {
        newOptions = [value];
      }

      if (globalSearchResultEntities && !globalSearchResultObjects) {
        newOptions = [...newOptions, ...globalSearchResultEntities];
      }

      if (globalSearchResultObjects && !globalSearchResultEntities) {
        newOptions = [...newOptions, ...globalSearchResultObjects];
      }

      if (globalSearchResultObjects && globalSearchResultEntities) {
        newOptions = [...newOptions, ...globalSearchResultObjects, ...globalSearchResultEntities];
      }
      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }, [
    dispatch,
    globalSearchResultEntities,
    globalSearchResultObjects,
    value,
    inputValue
  ]);

  useEffect(() => {
    if (!open && value !== null) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = (newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 2) {
      const requestData = {
        ...filters,
        inputValue: newInputValue
      };
      !value && dispatch(findEntityAndObjectByFiltersRequest(requestData));
    }
  };

  const handleClear = () => {
    setInputValue("");
    setValue(null);
  };

  const getResultsList = () => {
    setInputValue("");
    setShowList(true);
    setOpen(false);
  };

  const entityRows = entitiesResultList.map((entity: any) => {
    return createEntityData(
      entity?.id,
      entity?.name,
      entity?.age,
      entity?.objects_amount,
      entity?.objects_size,
      entity?.housingPercent,
      entity?.mixedPercent
    );
  });

  const entityColumns = [
    { id: "name", label: "Name/Company name" },
    { id: "age", label: "Age" },
    { id: "objects_amount", label: "Objects" },
    { id: "objects_size", label: "Total size, m²" },
    { id: "housingPercent", label: "% housing" },
    { id: "mixedPercent", label: "% mixed" },
  ];

  const objectRows = objectsResultList.map((object: any) => {
    return createObjectData(
      object?.object_id,
      object?.object_street_name,
      object?.full_size,
      object?.units,
      object?.price,
      object?.owner || "n/a"
    );
  });

  const objectColumns = [
    { id: "address", label: "Address" },
    { id: "size", label: "Size" },
    { id: "units_amount", label: "Units amount" },
    { id: "price", label: "Value" },
    { id: "owner", label: "owner" },
  ];

  return (
    <Box sx={styles.searchBlockHolder}>
      <Autocomplete
        open={open && inputValue.length >= 2}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        loading={processing}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        blurOnSelect
        fullWidth
        getOptionLabel={(option) => {
          return option.name && option.age
            ? `${option.name} ${option.age}`
            : option.name && !option.age
            ? `${option.name}`
            : `${option.street}`
            ? option.object_id
            : `${option.object_street_name}`
            ;
        }}
        onChange={(e, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  {option.name ? (
                    <Link
                      sx={styles.link}
                      href={`/leadgen/entity/${option.id}`}
                    >
                      <PersonIcon sx={styles.resultIcon} />
                      <Typography>
                        {option.name && option.age
                          ? `${option.name}, ${option.age} y.o.`
                          : `${option.name}`}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      sx={styles.link}
                      href={`/leadgen/object/${option.object_id}`}
                    >
                      <ApartmentIcon sx={styles.resultIcon} />
                      <Typography>{option.object_street_name}</Typography>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </li>
          );
        }}
        onInputChange={debounce((e, newInputValue) => handleChange(newInputValue), 1000) }
        renderInput={(params) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledSearch ref={params.InputProps.ref}>
              <StyledSearchIconWrapper>
                <SearchIcon sx={{ color: "#495057" }} />
              </StyledSearchIconWrapper>
              <StyledInputBase
                {...params}
                // onKeyDown={(e) => getResultsList()}
                // onKeyDown={(e) => getResultsList()} `/leadgen/entity/${leadID}`
                inputProps={{
                  ...params.inputProps,
                  placeholder: "Search",
                }}
              />
            </StyledSearch>
            {inputValue.length > 0 && (
              <Tooltip title={"Clear filed"}>
                <IconButton onClick={handleClear}>
                  <CloseIcon sx={{ color: "#495057" }} />
                </IconButton>
              </Tooltip>
            )}
            <Button
              variant="contained"
              sx={styles.buttonFind}
              onClick={(e) => getResultsList()}
            >
              Find
            </Button>
          </Box>
        )}
      />
      {showList && (
        <Box sx={styles.tableHolder}>
          {entitiesResultList.length > 0 && (
            <Box>
              <Typography
                sx={{
                  marginBottom: "15px",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontFamily: "typography.fontFamily",
                  color: "#495057",
                }}
                variant="h2"
              >
                Entities
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{}} size="medium">
                  <TableHead>
                    <TableRow>
                      {entityColumns.map((column: any) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entityRows.slice(pageEntities * rowsPerPageEntities, pageEntities * rowsPerPageEntities + rowsPerPageEntities).map((row: any) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {entityColumns.map((column: any) => {
                          const value = row[column.id];
                          return column.id === "name" ? (
                            <TableCell align="left" key={column.id}>
                              <Link
                                sx={styles.link}
                                href={`/leadgen/entity/${row.id}`}
                              >
                                {value}
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell align="left" key={column.id}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          3,
                          5,
                        ]}
                        colSpan={6}
                        count={entityRows.length}
                        rowsPerPage={rowsPerPageEntities}
                        // component="div"
                        page={pageEntities}
                        slotProps={{
                          select: {
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          },
                        }}
                        onPageChange={handleChangePageEntities}
                        onRowsPerPageChange={handleChangeRowsPerPageEntities}
                        // ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          )}
          {objectsResultList.length > 0 && (
            <Box>
              <Typography
                sx={{
                  margin: "15px 0",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontFamily: "typography.fontFamily",
                  color: "#495057",
                }}
                variant="h2"
              >
                Objects
              </Typography>
              <TableContainer component={Paper}>
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      {objectColumns.map((column: any) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objectRows.slice(pageObjects * rowsPerPageObjects, pageObjects * rowsPerPageObjects + rowsPerPageObjects).map((row: any) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {objectColumns.map((column: any) => {
                          const value = row[column.id];
                          return column.id === "address" ? (
                            <TableCell align="left" key={column.id}>
                              <Link
                                sx={styles.link}
                                href={`/leadgen/object/${row.id}`}
                              >
                                {value}
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell align="left" key={column.id}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          3,
                          5,
                        ]}
                        colSpan={5}
                        count={objectRows.length}
                        rowsPerPage={rowsPerPageObjects}
                        // component="div"
                        page={pageObjects}
                        slotProps={{
                          select: {
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          },
                        }}
                        onPageChange={handleChangePageObjects}
                        onRowsPerPageChange={handleChangeRowsPerPageObjects}
                        // ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

function mapStateToProps(state) {
  // @ts-ignore
  const { globalSearchResultEntities, globalSearchResultObjects, processing } = selectSearch(state);
  return { globalSearchResultEntities, globalSearchResultObjects, processing };
}

export default connect(mapStateToProps)(SearchBlock);
