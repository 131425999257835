import React from "react";
import PropTypes from "prop-types";
import { Box} from "@mui/material";
import styles from "./ModalFooter.styles";
import { modalTypes, buttonTypes } from "../../../constants/field-constants";
import DefaultButton from '../../button/Button';

export default function ModalFooter(props: any) {
  return (
    <Box sx={styles.intervalInput}>
      {props.type === modalTypes.CREATE || props.type === modalTypes.SAVE || props.type === modalTypes.UPDATE ? (
        <>
          <DefaultButton
            variant="contained"
            styleType="save"
            handleClick={() => {
              props.onSave();
              props.closeModal();
            }}
          >
            Save
          </DefaultButton>
          <DefaultButton
            variant="contained"
            styleType="cancel"
            handleClick={props.closeModal}
          >
            Cancel
          </DefaultButton>
        </>
      ) : props.type === modalTypes.DELETE ? (
        <>
          <DefaultButton
            variant="contained"
            styleType="delete"
            handleClick={() => {
              props.onSave();
              props.closeModal();
            }}
          >
            Delete
          </DefaultButton>
          <DefaultButton
            variant="contained"
            styleType="cancel"
            handleClick={props.closeModal}
          >
            Cancel
          </DefaultButton>
        </>
      ) : props.type === modalTypes.UNLINK ? (
        <>
          <DefaultButton
            variant="contained"
            styleType="unlink"
            handleClick={() => {
              props.onSave();
              props.closeModal();
            }}
          >
            Unlink
          </DefaultButton>
          <DefaultButton
            variant="contained"
            styleType="cancel"
            handleClick={props.closeModal}
          >
            Cancel
          </DefaultButton>
        </>
      ) : (
        <DefaultButton
          variant="contained"
          styleType="close"
          handleClick={props.closeModal}
        >
          Close
        </DefaultButton>
      )}
    </Box>
  );
}

ModalFooter.propTypes = {
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

ModalFooter.defaultProps = {
  onSave: () => {},
  closeModal: () => {},
};
