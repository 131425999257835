import { call, put, takeLatest } from "redux-saga/effects";
import {
  RECEIVE_LABELS_LIST_REQUEST,
  RECEIVE_TAGS_LIST_REQUEST,
  UPDATE_TAG_REQUEST,
  DELETE_TAG_REQUEST,
  UPDATE_LABEL_REQUEST,
  DELETE_LABEL_REQUEST,
  CREATE_NEW_LABEL_REQUEST,
  CREATE_NEW_TAG_REQUEST,
  GET_GOOGLE_DRIVE_REQUEST,
  DISCONNECT_GOOGLE_DRIVE_REQUEST, CHECK_DRIVE_CONNECTION_REQUEST,
} from "../../../common/constants/action-constants";
import ApiService, {API_HOST} from "../../../common/services/api.service";
import {
  receiveLabelsListError,
  receiveLabelsListSuccess,
  receiveTagsListError,
  receiveTagsListSuccess,
  updateTagError,
  receiveTagsListRequest,
  deleteTagError,
  receiveLabelsListRequest,
  updateLabelError,
  deleteLabelError,
  createNewLabelError,
  createNewTagError,
  connectToDriveError,
  disconnectDriveSuccess,
  disconnectDriveError,
  checkGoogleDriveConnectionSuccess,
  checkGoogleDriveConnectionError, connectToDriveSuccess,
} from "../actions";
import AlertService from "../../../common/services/notification.service";
import { notificationMessage } from "../../../common/constants/notication-constants";
import axios from "axios";

function* getLabelsList(action) {
  try {
    const labelsList = yield call(ApiService.get, "/labels");
    if (labelsList) {
      yield put(receiveLabelsListSuccess(labelsList.data));
    }
  } catch (e) {
    yield put(receiveLabelsListError(e));
  }
}

function* updateLabel(action) {
  try {
    const labels = yield call(
      ApiService.put,
      `/label/${action.id}`,
      action.name
    );
    if (labels.data.message) {
      AlertService.success(labels.data.message);
    }
    yield call(ApiService.get, "/labels");
    yield put(receiveLabelsListRequest());
  } catch (error) {
    yield put(updateLabelError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* deleteLabel(action) {
  try {
    const labelDelete = yield call(ApiService.delete, `/label/${action.id}`);
    if (labelDelete.data.message) {
      AlertService.success(labelDelete.data.message);
    }
    yield call(ApiService.get, "/labels");
    yield put(receiveLabelsListRequest());
  } catch (error) {
    yield put(deleteLabelError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* createLabel(action) {
  try {
    const newLabel = yield call(ApiService.post, "/label", action.label);
    if (newLabel.data) {
      AlertService.success(newLabel.data.message);
      yield put(receiveLabelsListRequest());
    }
  } catch (error) {
    yield put(createNewLabelError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* getTagsList(action) {
  try {
    const tagsList = yield call(ApiService.get, "/tag");
    if (tagsList) {
      yield put(receiveTagsListSuccess(tagsList.data));
    }
  } catch (e) {
    yield put(receiveTagsListError(e));
  }
}

function* updateTag(action) {
  try {
    const tags = yield call(ApiService.put, `/tag/${action.id}`, action.name);
    if (tags.data.message) {
      AlertService.success(tags.data.message);
    }
    yield call(ApiService.get, "/tag");
    yield put(receiveTagsListRequest());
  } catch (error) {
    yield put(updateTagError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* deleteTag(action) {
  try {
    const tagDelete = yield call(ApiService.delete, `/tag/${action.id}`);
    if (tagDelete.data.message) {
      AlertService.success(tagDelete.data.message);
    }
    yield call(ApiService.get, "/tag");
    yield put(receiveTagsListRequest());
  } catch (error) {
    yield put(deleteTagError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* createTag(action: {type: string, tag: any}) {
  try {
    const newTag = yield call(ApiService.post, "/tag", action.tag);
    if (newTag.data) {
      AlertService.success(newTag.data.message);
      yield put(receiveTagsListRequest());
    }
  } catch (error) {
    yield put(createNewTagError(error));
    AlertService.error(notificationMessage.BASE_ERROR_MESSAGE);
  }
}

function* driveConnect(action: {type: string}) {
  try {
    const driveAuthUrl = yield call(ApiService.get, "/user/drive/auth");
    if (driveAuthUrl) {
      const windowWidth = 800;
      const windowHeight = 600;
      let mainWidth =
        document.documentElement.clientWidth / 2 - windowWidth / 3;
      let mainHeight =
        document.documentElement.clientHeight / 2 - windowHeight / 4;
      window.open(
        driveAuthUrl.data,
        "Popup",
        `width=${windowWidth},height=${windowHeight}, top=${mainHeight}, left=${mainWidth}`
      );
      yield put(connectToDriveSuccess());
    }
  } catch (error) {
    yield put(connectToDriveError(error));
    console.log(error);
  }
}

function* driveDisconnect(action: {type: string}) {
  try {
    const result = yield call(ApiService.delete, "/user/drive/auth");
    if (result) {
      yield put(disconnectDriveSuccess(result.data));
    }
  } catch (error) {
    yield put(disconnectDriveError(error));
  }
}

function* driveConnectionCheck(action: {type: string}) {
  try {
    const result = yield call(ApiService.get, "/user/drive/check");
    if (result) {
      yield put(checkGoogleDriveConnectionSuccess(result.data));
    }
  } catch (e) {
    yield put(checkGoogleDriveConnectionError(e));

  }
}

export default function* actionSuperAdmin() {
  yield takeLatest(RECEIVE_LABELS_LIST_REQUEST, getLabelsList);
  yield takeLatest(RECEIVE_TAGS_LIST_REQUEST, getTagsList);
  yield takeLatest(UPDATE_LABEL_REQUEST, updateLabel);
  yield takeLatest(DELETE_LABEL_REQUEST, deleteLabel);
  yield takeLatest(UPDATE_TAG_REQUEST, updateTag);
  yield takeLatest(DELETE_TAG_REQUEST, deleteTag);
  yield takeLatest(CREATE_NEW_LABEL_REQUEST, createLabel);
  yield takeLatest(CREATE_NEW_TAG_REQUEST, createTag);
  yield takeLatest(GET_GOOGLE_DRIVE_REQUEST, driveConnect);
  yield takeLatest(DISCONNECT_GOOGLE_DRIVE_REQUEST, driveDisconnect);
  yield takeLatest(CHECK_DRIVE_CONNECTION_REQUEST, driveConnectionCheck);
}
