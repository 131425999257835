import {
  RECEIVE_LOGIN_REQUEST,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
} from "../../../common/constants/action-constants";

/**action checks if received from google-auth user exists in the database**/
export const receiveLoginRequest = (code, token, navigate, cb) => {
  return {
    type: RECEIVE_LOGIN_REQUEST,
    code,
    token,
    navigate,
    cb,
  };
};

export const getCurrentUserRequest = (navigate) => {
  return {
    type: GET_CURRENT_USER_REQUEST,
    navigate,
  };
};

export const getCurrentUserSuccess = (user) => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    user: user,
  };
};

export const getCurrentUserError = (error) => {
  console.log("error ", error);
  return {
    type: GET_CURRENT_USER_ERROR,
    error,
  };
};
