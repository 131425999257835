import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styles from "../GlobalSearch.styles";

import { selectSearch } from "../selectors";
import DefaultModal from "../../../common/components/modal/default-modal/DefaultModal";
import { types } from "../../../common/components/helpers/eventEmitter";
import ModalHeader from "../../../common/components/modal/modal-header/ModalHeader";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import ModalFooter from "../../../common/components/modal/modal-footer/ModalFooter";
import { modalTypes } from "../../../common/constants/field-constants";
import BlueSwitch from "../../../common/components/styled-components/Blueswitch";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import StyledSearch from "../../../common/components/styled-components/StyledSearch";
import StyledSearchIconWrapper from "../../../common/components/styled-components/StyledSearchIconWrapper";
import StyledInputBase from "../../../common/components/styled-components/StyledInputBase";
import { findObjectByFiltersRequest } from "../actions/search";
import AlertService from "../../../common/services/notification.service";
import { updateEntityRequest } from "../../leadgen/actions/entityDetails";

const ObjectSearchModal = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const [filters, setFilters] = useState({ byKadId: false }); /** will be used later **/
  const [objectsList, setObjectList] = useState([] as any);
  const { localSearchObjectResult, processing } = props;

  const handleOpen = (id) => {
    setEntityId(id);
  };

  useEffect(() => {
    let active = true;
    let newOptions: any = [];

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (active) {
      if (value) {
        newOptions = [value];
      }
      if (localSearchObjectResult) {
        newOptions = [...newOptions, ...localSearchObjectResult];
      }

      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }, [dispatch, localSearchObjectResult, value, inputValue]);

  const handleChange = (newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 2) {
      const requestData = {
        ...filters,
        inputValue: newInputValue,
        limit: 5,
      };
      !value && dispatch(findObjectByFiltersRequest(requestData));
    }
  };

  const handleChangeList = (object) => {
    const updatedObjectsList: any = [...objectsList];
    if (
      updatedObjectsList.some(
        (currentObject) => currentObject.object_id === object.object_id
      )
    ) {
      return AlertService.warning("This object is already in list");
    } else {
      updatedObjectsList.push(object);
    }

    setObjectList(updatedObjectsList);
  };

  const handleDeleteSelectedObject = (object_id) => {
    const updatedObjectListForSave = objectsList.filter(
      (object) => object.object_id !== object_id
    );
    setObjectList(updatedObjectListForSave);
  };

  const handleClear = () => {
    setInputValue("");
    setValue(null);
    setObjectList([]);
  };

  const handleAssignObjectsToEntity = () => {
    const actionTypes = ["objects"];
    const newLinkedObjectIds = objectsList.map((object) => object.object_id);
    let data = {
      newLinkedObjectIds,
    };
    dispatch(updateEntityRequest(false, actionTypes, entityId, data));
    setOpen(false);
    setEntityId(null);
  };

  return (
    <>
      <DefaultModal onOpen={handleOpen} nameEvent={types.openObjectSearchModal}>
        {({ closeModal }) => (
          <>
            <ModalHeader title="Objects search" />
            <Container>
              <Box sx={{ marginBottom: "20px", marginTop: "5px" }}>
                <Box sx={styles.filters}>
                  <FormControlLabel
                    value="end"
                    control={<BlueSwitch />}
                    label="Search by kad ID"
                    labelPlacement="end"
                    style={{ color: "#495057" }}
                  />
                  <Box sx={styles.filters}>
                    <Typography variant="body2" sx={{ fontFamily: "Poppins" }}>
                      SHOW:
                    </Typography>
                    <ButtonGroup variant="text" aria-label="Basic button group">
                      <Button sx={styles.splitButton}>+5</Button>
                      <Button sx={styles.splitButton}>-5</Button>
                    </ButtonGroup>
                  </Box>
                </Box>
                <Autocomplete
                  open={open && inputValue.length >= 2}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  options={options}
                  loading={processing}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={value}
                  blurOnSelect
                  fullWidth
                  getOptionLabel={(option) =>
                    `${option.objectAddress}, ${option.city}`
                  }
                  onChange={(e, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    handleChangeList(newValue);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: "flex" }}>
                            <ApartmentIcon sx={styles.resultIcon} />
                            <Grid item>
                              <Typography>
                                {`${option.objectAddress}, ${option.city}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                  onInputChange={(e, newInputValue) =>
                    handleChange(newInputValue)
                  }
                  renderInput={(params) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <StyledSearch ref={params.InputProps.ref}>
                        <StyledSearchIconWrapper>
                          <SearchIcon sx={{ color: "#495057" }} />
                        </StyledSearchIconWrapper>
                        <StyledInputBase
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Search",
                          }}
                        />
                      </StyledSearch>
                      {inputValue.length > 0 && (
                        <Tooltip title={"Clear filed"}>
                          <IconButton onClick={handleClear}>
                            <ClearIcon sx={{ color: "#495057" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                />
                <List>
                  {objectsList.length > 0 &&
                    objectsList.map((object, index) => (
                      <ListItem key={index}>
                        <ListItemText>{object.objectAddress}</ListItemText>
                        <IconButton
                          onClick={() =>
                            handleDeleteSelectedObject(object.object_id)
                          }
                        >
                          <ClearIcon sx={{ color: "#495057" }} />
                        </IconButton>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </Container>
            <ModalFooter
              closeModal={closeModal}
              type={modalTypes.SAVE}
              onSave={handleAssignObjectsToEntity}
            />
          </>
        )}
      </DefaultModal>
    </>
  );
};

function mapStateToProps(state) {
  // @ts-ignore
  const { localSearchObjectResult, processing } = selectSearch(state);
  return { localSearchObjectResult, processing };
}

export default connect(mapStateToProps)(ObjectSearchModal);
