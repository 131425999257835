import React, { ChangeEvent, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  MenuList,
  MenuItem,
  Paper,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./LPSForm.styles";
import LPSGeneralFieldsBlock from "./components/LPSGeneralFieldsBlock";
import ObjectPointsBlock from "./components/ObjectPointsBlock";
import ObjectTypesBlock from "./components/ObjectTypesBlock";
import LPSEntityKOABlock from "./components/LPSEntityKOABlock";
import {
  lpsFormTypes,
  userTypes,
} from "../../common/constants/field-constants";
import { Clear, Info, ContentCopy } from "@mui/icons-material";
import { connect, useDispatch } from "react-redux";
import { validateLPSConfig } from "../../common/components/helpers/validator";
import {
  createEditLPSConfigsRequest,
  deleteLPSConfigRequest,
  receiveLPSConfigsRequest,
} from "./actions";
import { selectUser } from "../login/selectors";
import { selectLPS } from "./selectors";

import DeleteIcon from "@mui/icons-material/Delete";

interface LPSFormProps {
  user?: any;
  lpsForm?: any;
}

const lpsFormInitialState = {
  id: null,
  user_id: null,
  lps_config_cities: [],
  general_fields: {
    name: "",
    calculation_type: lpsFormTypes.NON_INDUSTRIAL,
    config_type: "",
    object_type: "",
    entity_type: "",
    // city: "", //temp till search impl.
  },
  lpsc_object_condition_rules: [
    {
      variable: "",
      condition: "",
      condition_value: "",
      condition_points: "",
    },
  ],
  lpsc_object_types_rules: [
    {
      variable: "",
      condition: "",
      unit_size: "",
    },
  ],
  lps_config_entity_KOA_rules: {
    lps_config_entity_KOA_points: "",
  },
  action_types: [],
  cityForDelete: [],
  validationMode: false,
  objectRulesForDelete: [],
  objectTypeRulesForDelete: [],
  // base_points: 0,
  // config_type: "",
  // lps_config_entity_rules: {},
  // lpsc_entity_objects_rules: [],
  // lpsc_entity_age_rules: [],
  // lpsc_object_label_rules: [],
  // labelForDelete: [],
  // entityAgeForDelete: [],
  // entityObjForDelete: [],
};

const LPSForm = ({ user, lpsForm }: LPSFormProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [configCities, setConfigCities] = useState([])
  const [lpsFieldsForSave, setLpsFieldsForSave] = useState(lpsFormInitialState);
  const [filledFields, setFilledFields] = useState<object>({});
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { userId, userRole } = user;
  const { lpsConfigs } = lpsForm;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectConfig = (config) => {
    setConfigCities(config.lps_config_cities);
    setLpsFieldsForSave({
      id: config.id,
      user_id: config.user_id,
      lps_config_cities: config.lps_config_cities,
      general_fields: {
        name: config.name,
        calculation_type: config.calculation_type,
        config_type: config.config_type,
        object_type: config.object_type,
        entity_type: config.entity_type,
      },
      lpsc_object_condition_rules: config.lpsc_object_condition_rules
        ? config.lpsc_object_condition_rules
        : [
            {
              variable: "",
              condition: "",
              condition_value: "",
              condition_points: "",
            },
          ],
      lpsc_object_types_rules: config.lpsc_object_types_rules
        ? config.lpsc_object_types_rules
        : [
            {
              variable: "",
              condition: "",
              unit_size: "",
            },
          ],
      lps_config_entity_KOA_rules: config.lps_config_entity_KOA_rules
        ? config.lps_config_entity_KOA_rules
        : {
            lps_config_entity_KOA_points: "",
          },
      action_types: [],
      cityForDelete: [],
      validationMode: false,
      objectRulesForDelete: [],
      objectTypeRulesForDelete: [],
    });
    handleClose();
  };

  const handleChangeCity = (newCity) => {
    const currentType = "cities";
    setClearForm(false);
    const result = newCity.map((item, index) => {
      return ({
        id: index + 1,
        city_name: item.city,
      })
    });
    setLpsFieldsForSave({
      ...lpsFieldsForSave,
      // @ts-ignore
      lps_config_cities: [...result],
      // @ts-ignore
      action_types: lpsFieldsForSave.action_types.includes(currentType)
        ? lpsFieldsForSave.action_types
        : [currentType, ...lpsFieldsForSave.action_types],
    });
  }
  const handleChange = (
    fieldName,
    key,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const currentType = "general_fields"; //for config update
    let value = event.target.value;

    if (key === "name") value = value.toUpperCase();

    if (fieldName === "generalFields") {
      key === lpsFormTypes.KADASTER
        ? setLpsFieldsForSave({
            ...lpsFieldsForSave,
            lpsc_object_condition_rules: [
              {
                variable: "",
                condition: "",
                condition_value: "",
                condition_points: "",
              },
            ],
            lpsc_object_types_rules: [
              {
                variable: "",
                condition: "",
                unit_size: "",
              },
            ],
            general_fields: {
              ...lpsFieldsForSave.general_fields,
              [key]: value,
            },
            // @ts-ignore
            action_types: lpsFieldsForSave.action_types.includes(currentType)
              ? lpsFieldsForSave.action_types
              : [currentType, ...lpsFieldsForSave.action_types],
          })
        : setLpsFieldsForSave({
            ...lpsFieldsForSave,
            lps_config_entity_KOA_rules: {
              lps_config_entity_KOA_points: "",
            },
            general_fields: {
              ...lpsFieldsForSave.general_fields,
              [key]: value,
            },
            // @ts-ignore
            action_types: lpsFieldsForSave.action_types.includes(currentType)
              ? lpsFieldsForSave.action_types
              : [currentType, ...lpsFieldsForSave.action_types],
          });
    }
  };

  const handleChangeObjectConditionRules = (
    blockName,
    fieldName,
    { target },
    index
  ) => {
    const currentType = "lpsc_object_condition_rules";
    const editableBlock = lpsFieldsForSave.lpsc_object_condition_rules;
    editableBlock[index] = {
      ...editableBlock[index],
      [fieldName]: target.value,
    };

    return setLpsFieldsForSave({
      ...lpsFieldsForSave,
      lps_config_entity_KOA_rules: {
        // @ts-ignore
        id: lpsFieldsForSave.lps_config_entity_KOA_rules.id,
        lps_config_entity_KOA_points: "",
      },
      [blockName]: [...editableBlock],
      // @ts-ignore
      action_types: lpsFieldsForSave.action_types.includes(currentType)
        ? [...lpsFieldsForSave.action_types, "lps_config_entity_KOA_rules"]
        : [
            currentType,
            "lps_config_entity_KOA_rules",
            ...lpsFieldsForSave.action_types,
          ],
    });
  };

  const handleChangeObjectTypesRules = (
    blockName,
    fieldName,
    { target },
    index
  ) => {
    const currentType = "lpsc_object_types_rules";
    const editableBlock = lpsFieldsForSave.lpsc_object_types_rules;
    editableBlock[index] = {
      ...editableBlock[index],
      [fieldName]: target.value,
    };

    return setLpsFieldsForSave({
      ...lpsFieldsForSave,
      lps_config_entity_KOA_rules: {
        // @ts-ignore
        id: lpsFieldsForSave.lps_config_entity_KOA_rules.id,
        lps_config_entity_KOA_points: "",
      },
      [blockName]: [...editableBlock],
      // @ts-ignore
      action_types: lpsFieldsForSave.action_types.includes(currentType)
        ? [...lpsFieldsForSave.action_types, "lps_config_entity_KOA_rules"]
        : [
            currentType,
            "lps_config_entity_KOA_rules",
            ...lpsFieldsForSave.action_types,
          ],
    });
  };

  const handleChangeEntityKOA = (blockName, fieldName, { target }) => {
    const currentType = "lps_config_entity_KOA_rules";
    let editableBlock = lpsFieldsForSave.lps_config_entity_KOA_rules;
    editableBlock = {
      ...editableBlock,
      [fieldName]: target.value,
    };

    return setLpsFieldsForSave({
      ...lpsFieldsForSave,
      // @ts-ignore
      objectRulesForDelete: [...lpsFieldsForSave.lpsc_object_condition_rules],
      // @ts-ignore
      objectTypeRulesForDelete: [...lpsFieldsForSave.lpsc_object_types_rules],
      [blockName]: editableBlock,
      // @ts-ignore
      action_types: lpsFieldsForSave.action_types.includes(currentType)
        ? [
            ...lpsFieldsForSave.action_types,
            "lpsc_object_condition_rules",
            "lpsc_object_types_rules",
          ]
        : [
            currentType,
            "lpsc_object_condition_rules",
            "lpsc_object_types_rules",
            ...lpsFieldsForSave.action_types,
          ],
    });
  };

  const handleCancel = () => {
    setClearForm(true);
    setFilledFields({});
    setLpsFieldsForSave({
      id: null,
      user_id: null,
      lps_config_cities: [],
      general_fields: {
        name: "",
        calculation_type: lpsFormTypes.NON_INDUSTRIAL,
        config_type: "",
        object_type: "",
        entity_type: "",
        // city: "", //temp till search impl.
        // city:[],
      },
      lpsc_object_condition_rules: [
        {
          variable: "",
          condition: "",
          condition_value: "",
          condition_points: "",
        },
      ],
      lpsc_object_types_rules: [
        {
          variable: "",
          condition: "",
          unit_size: "",
        },
      ],
      lps_config_entity_KOA_rules: {
        lps_config_entity_KOA_points: "",
      },
      action_types: [],
      cityForDelete: [],
      validationMode: false,
      objectRulesForDelete: [],
      objectTypeRulesForDelete: [],
    });
  };

  const handleGetAllConfigs = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(receiveLPSConfigsRequest());
  };

  const handleAdd = (type, blockName) => {
    const additableType = lpsFieldsForSave[type] || {};
    let additableTypeBlock;
    if (blockName === "lpsc_object_condition_rules") {
      additableTypeBlock = additableType[blockName] || {};
      return setLpsFieldsForSave({
        ...lpsFieldsForSave,
        [blockName]: [...additableType, additableTypeBlock],
      });
    }
    if (blockName === "lpsc_object_types_rules") {
      additableTypeBlock = additableType[blockName] || {};
      return setLpsFieldsForSave({
        ...lpsFieldsForSave,
        [blockName]: [...additableType, additableTypeBlock],
      });
    }
  };

  const handleDelete = (type, blockName, index) => {
    const additableType = lpsFieldsForSave[type] || {};
    const additableTypeBlock = additableType[blockName] || [];
    let deletedItem;
    const currentType = type;
    if (blockName === "lpsc_object_condition_rules") {
      deletedItem = additableType.splice(index, 1);
      if (Object.keys(deletedItem[0]).length > 0 && deletedItem[0].id) {
        return setLpsFieldsForSave({
          ...lpsFieldsForSave,
          [type]: [...additableType],
          // @ts-ignore
          objectRulesForDelete: [
            // @ts-ignore
            ...lpsFieldsForSave.objectRulesForDelete,
            // @ts-ignore
            deletedItem[0],
          ],
          // @ts-ignore
          action_types: lpsFieldsForSave.action_types.includes(currentType)
            ? lpsFieldsForSave.action_types
            : [currentType, ...lpsFieldsForSave.action_types],
        });
      } else {
        return setLpsFieldsForSave({
          ...lpsFieldsForSave,
          [type]: [...additableType],
        });
      }
    }
    if (blockName === "lpsc_object_types_rules") {
      deletedItem = additableType.splice(index, 1);
      if (Object.keys(deletedItem[0]).length > 0 && deletedItem[0].id) {
        return setLpsFieldsForSave({
          [type]: [...additableType],
          // @ts-ignore
          objectRulesForDelete: [
            // @ts-ignore
            ...lpsFieldsForSave.objectTypeRulesForDelete,
            // @ts-ignore
            deletedItem[0],
          ],
          // @ts-ignore
          action_types: lpsFieldsForSave.action_types.includes(currentType)
            ? lpsFieldsForSave.action_types
            : [currentType, ...lpsFieldsForSave.action_types],
        });
      } else {
        return setLpsFieldsForSave({
          ...lpsFieldsForSave,
          [type]: [...additableType],
        });
      }
    }

    setLpsFieldsForSave({
      ...lpsFieldsForSave,
      [type]: { ...additableType, [blockName]: [...additableTypeBlock] },
    });
  };
  const cloneLpsConfig = () => {
    setLpsFieldsForSave({
      id: null,
      user_id: userId,
      lps_config_cities: [],
      general_fields: {
        name: "",
        calculation_type: lpsFieldsForSave.general_fields.calculation_type,
        config_type: lpsFieldsForSave.general_fields.config_type,
        object_type: lpsFieldsForSave.general_fields.object_type,
        entity_type: lpsFieldsForSave.general_fields.entity_type,
        // city: "", //temp till search impl.
        // city:[],
      },
      lpsc_object_condition_rules: lpsFieldsForSave.lpsc_object_condition_rules,
      lpsc_object_types_rules: lpsFieldsForSave.lpsc_object_types_rules,
      lps_config_entity_KOA_rules: lpsFieldsForSave.lps_config_entity_KOA_rules,
      action_types: lpsFieldsForSave.action_types,
      cityForDelete: [],
      validationMode: false,
      objectRulesForDelete: [],
      objectTypeRulesForDelete: [],
      // base_points: 0,
      // config_type: "",
      // lps_config_entity_rules: {},
      // lpsc_entity_objects_rules: [],
      // lpsc_entity_age_rules: [],
      // lpsc_object_label_rules: [],
      // labelForDelete: [],
      // entityAgeForDelete: [],
      // entityObjForDelete: [],
    });
  };

  const saveLPSConfig = () => {
    const isValidated = validateLPSConfig(lpsFieldsForSave);
    setFilledFields({...isValidated});
    if ( !Object.keys(isValidated).every((item) => isValidated[item] === true)) {
      return setLpsFieldsForSave({
        ...lpsFieldsForSave,
        user_id: userId,
        validationMode: true,
      });
    }
    if (userRole !== userTypes.USER) {
      /** probably later we need to add props.user_id here to check who created this config**/
      isValidated &&
        dispatch(
          createEditLPSConfigsRequest({
            ...lpsFieldsForSave,
          })
        );
    }

    handleCancel();
  };

  const removeLPSConfig = () => {
    if (userRole !== userTypes.USER) {
      dispatch(deleteLPSConfigRequest(lpsFieldsForSave.id));
    }

    handleCancel()
    setFilledFields({});
  };
  return (
    <>
      <Box>
        <AppBar position="relative" sx={styles.dataBox}>
          <Toolbar sx={styles.toolBar}>
            <Typography component="div" sx={styles.headerContent}>
              Lead priority system (LPS)
            </Typography>
            <Button
              color="inherit"
              sx={styles.buttonContent}
              onClick={handleGetAllConfigs}
            >
              SELECT LPS CONFIG
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuList>
                {lpsConfigs &&
                  lpsConfigs.length > 0 &&
                  lpsConfigs.map((config: any) => (
                    <MenuItem
                      key={config.id}
                      onClick={() => selectConfig(config)}
                    >
                      {config.name}
                    </MenuItem>
                  ))}
              </MenuList>
            </Popover>
          </Toolbar>
        </AppBar>
      </Box>
      <Paper sx={styles.formHolder}>
        <Box sx={styles.menuPanel}>
          <Tooltip title={"Delete config"}>
            <IconButton onClick={removeLPSConfig}>
              <DeleteIcon sx={{ color: "#495057" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Clear form"}>
            <IconButton onClick={handleCancel}>
              <Clear sx={{ color: "#495057" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Clone LPS config by current"}>
            <IconButton onClick={cloneLpsConfig}>
              <ContentCopy sx={{ color: "#495057" }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              'Clue: note, that "units amount" variable in "object point condition rules" is required'
            }
          >
            <IconButton>
              <Info sx={{ color: "#495057" }} />
            </IconButton>
          </Tooltip>
        </Box>
        {lpsFieldsForSave.general_fields.calculation_type ===
        lpsFormTypes.KADASTER ? (
          <Box>
            <LPSGeneralFieldsBlock
              calculationType={lpsFieldsForSave.general_fields.calculation_type}
              actionChangeCity={handleChangeCity}
              onChange={handleChange}
              resetForm={clearForm}
              generalFields={lpsFieldsForSave.general_fields}
              lpsConfigCities={lpsFieldsForSave.lps_config_cities}
              configLpsCities={configCities}
              isFilledFields ={filledFields }
              changeFilledFields={setFilledFields}
            />
            <LPSEntityKOABlock
              onChange={handleChangeEntityKOA}
              entityKOAValue={
                lpsFieldsForSave.lps_config_entity_KOA_rules
                  .lps_config_entity_KOA_points
              }
              isFilledFields ={filledFields }
              changeFilledFields={setFilledFields}
            />
          </Box>
        ) : (
          <Box>
            <LPSGeneralFieldsBlock
              calculationType={lpsFieldsForSave.general_fields.calculation_type}
              actionChangeCity={handleChangeCity}
              onChange={handleChange}
              resetForm={clearForm}
              generalFields={lpsFieldsForSave.general_fields}
              lpsConfigCities={lpsFieldsForSave.lps_config_cities}
              configLpsCities={configCities}
              isFilledFields ={filledFields }
              changeFilledFields={setFilledFields}
            />
            <ObjectPointsBlock
              onChange={handleChangeObjectConditionRules}
              objectPointsRules={lpsFieldsForSave.lpsc_object_condition_rules}
              calculationType={lpsFieldsForSave.general_fields.calculation_type}
              onAdd={handleAdd}
              onDelete={handleDelete}
            />
            <ObjectTypesBlock
              onChange={handleChangeObjectTypesRules}
              objectTypesRules={lpsFieldsForSave.lpsc_object_types_rules}
              onAdd={handleAdd}
              onDelete={handleDelete}
            />
          </Box>
        )}
        <Box sx={styles.buttonHolder}>
          <Button
            variant="contained"
            sx={styles.buttonSave}
            onClick={saveLPSConfig}
          >
            Save
          </Button>
          <Button
            variant="contained"
            sx={styles.buttonCansel}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </>
  );
};

function mapStateToProps(state) {
  const lpsForm = selectLPS(state);
  const user = selectUser(state);
  return { user, lpsForm };
}

export default connect(mapStateToProps)(LPSForm);
