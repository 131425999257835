import ApiService from "../../services/api.service";
import { entityTypes, lpsFormTypes } from "../../constants/field-constants";
import AlertService from "../../services/notification.service";

function hasDuplicates(variablesArray: any) {
  return new Set(variablesArray).size !== variablesArray.length;
}

export const emailValidator = (email: string) => {
  let isValid = false;
  if (email !== "")
    isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  return isValid;
};

export const userFormValidator = (user: any) => {
  let validationResult: {
    isFilledName: boolean;
    isFilledSurname: boolean;
    isFilledEmail: boolean;
    isFilledRole: boolean;
    isFilledDefaultLpsProfile:boolean;
    isFilledIP: boolean;
    isValidEmail: boolean;
  } = {
    isFilledName: true,
    isFilledSurname: true,
    isFilledEmail: true,
    isFilledRole: true,
    isFilledDefaultLpsProfile:true,
    isFilledIP: true,
    isValidEmail: true,
  };

  if (!user.name) validationResult.isFilledName = false;
  if (!user.surname) validationResult.isFilledSurname = false;
  if (!user.email) {
    validationResult.isFilledEmail = false;
    validationResult.isValidEmail = false;
  } else {
    const isValidEmail = emailValidator(user.email);

    if (!isValidEmail) validationResult.isValidEmail = false;
  }
  if (!user.role_id) validationResult.isFilledRole = false;
  if (
    user.user_ip_whitelist?.length &&
    user.user_ip_whitelist.some((item) => !item.ip)
  )
    validationResult.isFilledIP = false;

  return validationResult;
};

export const kadIdValidator = async (type: string, kadId: any) => {
  if (type === entityTypes.PERSON || type === entityTypes.COMPANY) {
    return await ApiService.post(`/${type}/kad_id`, kadId);
  }
};

export const entityFormValidator = (entity: any) => {
  let validationResult: {
    isFilledLastName: boolean;
    isFilledCompanyName: boolean;
  } = {
    isFilledLastName: true,
    isFilledCompanyName: true,
  };

  if (
    entity.general_fields.entity_type === entityTypes.PERSON &&
    !entity.general_fields.last_name
  ) {
    validationResult.isFilledLastName = false;
  }
  if (
    entity.general_fields.entity_type === entityTypes.COMPANY &&
    !entity.general_fields.company_name
  ) {
    validationResult.isFilledCompanyName = false;
  }

  return validationResult;
};

export const objectFormValidator = (object: any) => {
  let validationResult: {
    isFilledKadId: boolean;
    isFilledCityCode: boolean;
    isFilledPlotNumber: boolean;
    isFilledSection: boolean;
    isFilledPlotIndex: boolean;
    isFilledCity: boolean;
  } = {
    isFilledKadId: true,
    isFilledCityCode: true,
    isFilledPlotNumber: true,
    isFilledSection: true,
    isFilledPlotIndex: true,
    isFilledCity: true,
  };

  if (!object.general_fields.kadaster_id)
    validationResult.isFilledKadId = false;
  if (!object.plot.city_code) validationResult.isFilledCityCode = false;
  if (!object.plot.section) validationResult.isFilledPlotNumber = false;
  if (!object.plot.plot_number) validationResult.isFilledSection = false;
  if (!object.plot.index) validationResult.isFilledPlotIndex = false;
  if (!object.address.city_name) validationResult.isFilledCity = false;

  return validationResult;
};

export const validateLPSConfig = (data: any) => {

  const warning = () => {
    AlertService.warning("Please fill in all required fields");
  }
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    lps_config_cities,
    general_fields,
    lpsc_object_condition_rules,
    lps_config_entity_KOA_rules,
  } = data;
  let result: {
    isFilledFieldName: boolean;
    isFilledFieldCity: boolean;
    isFilledFieldObjectConditionRulesVariable: boolean;
    isFilledFieldConfigEntityKoaPoints: boolean;
    isNotDuplicatedFields: boolean;
    isFilledFieldConfigType: boolean;
    isFilledFieldObjectType: boolean;
    isFilledFieldEntityType: boolean;
  } = {
    isFilledFieldName: true,
    isFilledFieldCity: true,
    isFilledFieldObjectConditionRulesVariable: true,
    isFilledFieldConfigEntityKoaPoints: true,
    isNotDuplicatedFields: true,
    isFilledFieldConfigType: true,
    isFilledFieldObjectType: true,
    isFilledFieldEntityType: true,
  }

  if (!general_fields.name) {
    result.isFilledFieldName = false;
  }
  if (!general_fields.config_type) {
    result.isFilledFieldConfigType = false;
  }
  // if (!general_fields.object_type) {
  //   result.isFilledFieldObjectType = false;
  // }
  // if (!general_fields.entity_type) {
  //   result.isFilledFieldEntityType = false;
  // }

  if (general_fields.calculation_type === lpsFormTypes.NON_INDUSTRIAL
    || general_fields.calculation_type === lpsFormTypes.INDUSTRIAL  ) {
    if (lpsc_object_condition_rules.some(
      (rule: any) => rule.variable === "")) {
      result.isFilledFieldObjectConditionRulesVariable = false;
    }
  }
  if (general_fields.calculation_type === lpsFormTypes.NON_INDUSTRIAL) {
    if (!lps_config_cities || !lps_config_cities.length) {
      result.isFilledFieldCity = false;
    }
  }
  if (
    general_fields.calculation_type === lpsFormTypes.KADASTER &&
    lps_config_entity_KOA_rules.lps_config_entity_KOA_points === ""
  ) {
    result.isFilledFieldConfigEntityKoaPoints = false;
  }

  const variablesArray = lpsc_object_condition_rules.map(
    (rule: any) => rule.variable
  );

  if (hasDuplicates(variablesArray)) {
    AlertService.warning(
      "You have duplicated fields in object condition rules"
    );
    result.isNotDuplicatedFields = false;
    return result;
  }

  if ( !Object.keys(result).every((item) => result[item] === true)){
    warning();
  }

  if (general_fields.calculation_type === lpsFormTypes.NON_INDUSTRIAL) {
    if (
      !variablesArray.some(
        (rule: any) =>  rule === 'units amount'
      ) && !variablesArray.some(
        (rule: any) =>  rule === ''
      )
    )
    {
      AlertService.warning("Units amount is a required field");
      result.isFilledFieldObjectConditionRulesVariable = false;
    }
  }

  return result;
};
