import { Grid, Box } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { selectLeadgen } from "../../selectors";
import Object from "./Object";
import MapGoogleDriveBlock from "../common/MapGoogleDriveBlock";


interface ObjectBlockProps {
  objectId?: string | undefined;
}

const mapStyles = {
  height: "78vh",
  marginTop: "1rem",
};

const ObjectBlock = (props: ObjectBlockProps) => {
  const { objectId } = props
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columns={2} spacing={3}>
        <Grid item xs={2} md={1} >
            <Object objectId={objectId} />
        </Grid>
        <Grid item xs={2} md={1}>
          <MapGoogleDriveBlock mapStyles={mapStyles} objectId={objectId}/>
        </Grid>
      </Grid>
    </Box>
  );
};

function mapStateToProps(state: any) {
  const { entityObjectsList, objectsAmount } = selectLeadgen(state);
  return { entityObjectsList, objectsAmount };
}

export default connect(mapStateToProps)(ObjectBlock);
