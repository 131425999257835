import { createSelector } from "reselect";

const superAdminState = (state) => {
  if (state && state.superAdmin) {
    return state.superAdmin;
  }
  return null;
};

const superAdminSelector = createSelector(
  [superAdminState],
  (state) => state // return superAdmin
);

const labelsSelector = createSelector(
  [superAdminSelector],
  (state) => state.labelsList
);

const tagsSelector = createSelector(
  [superAdminSelector],
  (state) => state.tagsList
);

const driveConnectionSelector = createSelector(
  [superAdminSelector],
  (state) => state.driveConnection
);

export const selectSuperAdmin: any = createSelector(
  [superAdminSelector, labelsSelector, tagsSelector, driveConnectionSelector],
  (superAdmin, labelsList, tagsList, driveConnection) => {
    const driveAuthUrl =
      superAdmin && superAdmin.driveAuthUrl ? superAdmin.driveAuthUrl : null;
    const driveConnectionCheck = driveConnection ? driveConnection.connection : false;

      return {
      superAdmin,
      labelsList,
      tagsList,
      driveAuthUrl,
      driveConnectionCheck
    };
  }
);
