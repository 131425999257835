import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_NEW_FILES_GOOGLE_DRIVE_REQUEST,
  RECEIVE_DOCS_GOOGLE_DRIVE_REQUEST,
  SEND_NEW_FILES_GOOGLE_DRIVE_REQUEST
} from '../../../common/constants/action-constants';
import {
  addNewFilesGoogleDriveError,
  addNewFilesGoogleDriveSuccess,
  receiveDocsGoogleDriveError,
  receiveDocsGoogleDriveSuccess,
  sendNewFilesGoogleDriveError,
  sendNewFilesGoogleDriveSuccess
} from '../actions/docsGoogleDrive';
import ApiService from '../../../common/services/api.service';
import AlertService from '../../../common/services/notification.service';
import { notificationMessage } from '../../../common/constants/notication-constants';

function* getDocsGoogleDrive(action: any) {
  try {
    const docsGoogleDrive: any = yield call(ApiService.post, `/drive/docs/`, action.data);
    if (docsGoogleDrive) {
      yield put(receiveDocsGoogleDriveSuccess(docsGoogleDrive.data));
    }
    yield;
  } catch (error) {
    yield put(receiveDocsGoogleDriveError(error));
  }
}

function* sendNewFilesGoogleDrive(action: any) {
  try {
    const sendFilesData: any = yield  call(
      ApiService.post,
      '/upload/files',
      action.data
    );
    if (sendFilesData) {
      yield put(sendNewFilesGoogleDriveSuccess(sendFilesData.data));
    }
  } catch (error) {
    yield put(sendNewFilesGoogleDriveError(error));
  }
}

function* saveFilesGoogleDrive(action: any) {
  try {
    const filesGoogleDrive: any = yield call(
      ApiService.put,
      '/upload/save_files',
      {
        data: action.data,
        idFolder: action.idFolder,
        id: action.id,
        type: action.types
      }
    );
    if (filesGoogleDrive) {
      yield put(addNewFilesGoogleDriveSuccess(filesGoogleDrive.data));
    }
  } catch (error) {
    yield put(addNewFilesGoogleDriveError(error));
  }
}


export default function* actionDocsGoogleDrive() {
  yield takeLatest(RECEIVE_DOCS_GOOGLE_DRIVE_REQUEST, getDocsGoogleDrive);
  yield takeLatest(SEND_NEW_FILES_GOOGLE_DRIVE_REQUEST, sendNewFilesGoogleDrive);
  yield takeLatest(ADD_NEW_FILES_GOOGLE_DRIVE_REQUEST, saveFilesGoogleDrive);

}